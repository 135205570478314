import { connectedApiService as api } from '../index'
import { handleActionError } from './helpers'
import { createOrEditItem, deleteItem } from './form'
import { showNotification } from './notifications'

export const createEvent = values => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'eventsList',
    })
  )
export const updateEventSettings = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'eventBookings',
    })
  )
export const editEventComments = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `events/${id}/comments`,
    })
  )
export const editEventCoordinators = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `events/${id}/coordinators`,
    })
  )
export const addShareEventUser = (id, eventId) => async dispatch =>
  await dispatch(
    createOrEditItem({}, { endpoint: `shareEvent/${eventId}/shareUser/${id}` })
  )
export const removeShareEventUser = (id, eventId) => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      { isEdit: true, endpoint: `shareEvent/${eventId}/shareUser/${id}` }
    )
  )
export const toggleUserStatus = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: 'eventBookings',
    })
  )
export const inviteEventUser = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `events/${id}/invite`,
      successMessage: 'User was successfully invited',
    })
  )
