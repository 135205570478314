import React, { useState, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { connectedApiService as api } from '../../index'
import { Button, Nav, Badge } from 'react-bootstrap'
import {
  withRouter,
  useLocation,
  NavLink,
  useParams,
  Link,
} from 'react-router-dom'
import { SmallUp, SmallDown } from '../Responsive/breakpoints'
import NotificationsDriver from '../../router/routes/Driver/DriverApp/NotificationsDriver'
import {
  getPartnerDetails,
  logout,
  markPopUpAsSeenPartner,
  updatePartnerBanner,
} from 'actions'
import { partnerHostLive, partnerHostStaging } from '../../constants'
import { isAndroid, isIos, isMobile } from 'helpers'
import useBottomScrollListener from 'hooks/useBottomScrollListener'
import WhatsNewModal from 'components/WhatsNewModal'
const Host = window.location.hostname

const isMobileBrowser = isMobile()
const DefaultDriverDesktopLayout = ({
  history,
  match,
  data = null,
  renderContent = null,
  reloadList,
  isAccountLoading = false,
  userName,
  company = null,
  notifications,
  logout,
  isCarRental,
  isChauffeur,
  currentPartnerType,
  togglePartnerType = () => {},
  getPartnerDetails,
  isDriver,
  isPartner,
  partnerName,
  isLocalSupplier,
  whatsNewPartner,
  markPopUpAsSeenPartner,
  updatePartnerBanner,
  showSwitchBanner,
  onScrollBottom = () => {},
}) => {
  const apiHost = process.env.REACT_APP_APIHOST
  const { token, bookingId } = useParams()

  let partnerType = localStorage.getItem('partnerType')
    ? localStorage.getItem('partnerType')
    : isCarRental
    ? 'rental'
    : 'chauffeur'

  if (token === 'null' && apiHost === 'https://business.rolzo.com/api') {
    if (isAndroid()) {
      window.location.replace(
        'https://play.google.com/store/apps/details?id=com.rolzo.partner&hl=en_US&gl=US'
      )
    } else if (isIos()) {
      window.location.replace(
        `https://apps.apple.com/ae/app/rolzo-partner/id1612210928`
      )
    }
  }

  const scrollRef = useRef(null)
  useBottomScrollListener(onScrollBottom, scrollRef, 100, 300)

  let [loadingLocation, setLoadingLocation] = useState(null)
  let [title, setTitle] = useState(false)
  let [details, setDetails] = useState(null)
  let [isNotificationExpanded, setNotificationExpanded] = useState(false)
  let [elementWidth, setElementWidth] = useState(0)
  let [showBanner, setShowBanner] = useState(
    typeof showSwitchBanner === 'boolean' ? showSwitchBanner : false
  )
  const [showWhatsNew, setShowWhatsNew] = useState(false)

  function onPanelExpanded(expanded) {
    if (document.getElementById('app-page') === null) {
      return
    }

    if (expanded) {
      document
        .getElementById('app-page')
        .firstElementChild.classList.add('page-with-panel')
    } else {
      // document
      //   .getElementById('app-page')
      //   .firstElementChild.classList.remove('page-with-panel')
    }
  }
  function closePanel() {
    setNotificationExpanded(false)
    onPanelExpanded(false)
  }
  let expanded = isNotificationExpanded
  onPanelExpanded(expanded)

  let location = useLocation()
  let currentLocation = location.pathname

  if (loadingLocation != null) {
    currentLocation = loadingLocation
  }

  let isBookingsSelected =
    currentLocation &&
    (currentLocation.endsWith('/new-bookings') ||
      currentLocation.endsWith('/completed') ||
      currentLocation.endsWith('/planned'))

  let isAccountSelected =
    currentLocation && currentLocation.endsWith('/account')

  let isHomePageSelected =
    currentLocation && currentLocation.endsWith('/home-page')

  const isCompleted = currentLocation && currentLocation.endsWith('/completed')

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        await getPartnerDetails(token)
      } catch (err) {
        console.log(err)
      }
    }
    const checkTokenValidityDetails = async () => {
      try {
        const url =
          partnerType === 'rental'
            ? `external/partnerRentalToken/${token}`
            : `external/partnerToken/${token}`
        const response = await api.get(url)
        setDetails(response.data)
      } catch (err) {
        console.log(err)
      }
    }
    if (isPartner === null || isDriver === null) {
      checkTokenValidity()
    } else {
      checkTokenValidityDetails()
    }
  }, [])

  useEffect(() => {
    const checkFcmAvailable = async () => {
      try {
        const url =
          partnerType === 'rental'
            ? `external/partnerRentalToken/${token}`
            : `external/partnerToken/${token}`
        const response = await api.get(url)
        if (
          !response.data.fcmAvailable &&
          apiHost === 'https://business.rolzo.com/api'
        ) {
          if (isAndroid()) {
            window.location.replace(
              'https://play.google.com/store/apps/details?id=com.rolzo.partner&hl=en_US&gl=US'
            )
          } else if (isIos()) {
            window.location.replace(
              `https://apps.apple.com/ae/app/rolzo-partner/id1612210928`
            )
          }
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (token) {
      checkFcmAvailable()
    }
  }, [token])

  useEffect(() => {
    if (title && (title.includes('Booking') || title.includes('Offer'))) {
      var element = document.getElementsByClassName('driver-info-layout')
      if (element && element[0]) {
        var positionInfo = element[0].getBoundingClientRect()
        setElementWidth(positionInfo.width)
      } else {
        var elementWithBanner = document.getElementsByClassName(
          'driver-info-layout-banner'
        )
        if (elementWithBanner && elementWithBanner[0]) {
          var positionInfo = elementWithBanner[0].getBoundingClientRect()
          setElementWidth(positionInfo.width)
        }
      }
    } else if (title && title.includes('Hello')) {
      var element = document.getElementsByClassName('driver-booking-container')
      if (element && element[0]) {
        var positionInfo = element[0].getBoundingClientRect()
        setElementWidth(positionInfo.width)
      }
    }
  }, [title])

  useEffect(() => {
    if (loadingLocation) {
      history.push(loadingLocation)
    }
  }, [loadingLocation])

  useEffect(() => {
    if (typeof showSwitchBanner === 'boolean') {
      setShowBanner(showSwitchBanner)
    }
  }, [showSwitchBanner])

  useEffect(() => {
    if (data && data.number) {
      if (
        data.supplierInfo &&
        (data.supplierInfo.status === 'expired' ||
          data.supplierInfo.status === 'declined' ||
          data.supplierInfo.status === 'dispatched' ||
          data.supplierInfo.status === 'opened')
      )
        setTitle(`Offer #${data.number}`)
      else setTitle(`Booking #${data.number}`)
    } else if (currentLocation.endsWith('car/add')) setTitle('New vehicle')
    else if (currentLocation.endsWith('chauffeur/add')) {
      partnerType === 'rental'
        ? setTitle('New agent')
        : setTitle('New chauffeur')
    } else if (currentLocation.includes('settings')) setTitle('Company')
    else if (currentLocation.endsWith('home-page')) setTitle('Home')
    else if (currentLocation.endsWith('support')) setTitle('Support')
  }, [currentLocation, userName, data])

  const MenuLink = ({ path, title, icon }) => {
    return (
      <NavLink className='nav-link' to={path ? path : currentLocation}>
        <SmallUp>
          <img
            className={
              path.includes('home-page')
                ? 'nav-link-img-partner-home'
                : 'nav-link-img-partner'
            }
            src={'/images/' + icon}
            alt=''
          />
        </SmallUp>
        <span
          style={{
            opacity: '1',
            transition: 'opacity 0.28s',
          }}
        >
          <div style={{ display: 'flex', alignContent: 'center' }}>{title}</div>
        </span>
      </NavLink>
    )
  }

  const MenuItem = ({
    title,
    path,
    iconInactive,
    iconActive,
    currentPath,
    childAllowedRoutes,
  }) => {
    if (!childAllowedRoutes) {
      return (
        <MenuLink
          key={path}
          title={title}
          icon={
            path && currentPath && currentPath.startsWith(path)
              ? iconActive
              : iconInactive
          }
          path={path}
        />
      )
    }
  }

  const BottomMenuLink = ({ title, icon, isSelected, onClickHandle }) => {
    return (
      <div
        className='bottom-nav-link align-self-center mx-5'
        onClick={onClickHandle}
      >
        <div
          className='bottom-nav-img-wrapper'
          style={
            title === 'Quotes'
              ? { paddingTop: '2.2px' }
              : title === 'Support'
              ? { paddingTop: '1px' }
              : {}
          }
        >
          <img
            className='bottom-nav-link-img'
            src={'/images/' + icon}
            alt=''
            style={
              title === 'Quotes'
                ? { height: '17px' }
                : title === 'Support'
                ? { height: '22px' }
                : {}
            }
          />
        </div>
        <div className={`${isSelected ? 'selected-nav' : ''}`}>{title}</div>
      </div>
    )
  }
  const BootMenuItem = ({
    title,
    path,
    icon,
    currentPath,
    childAllowedRoutes,
    existUnread,
    onClickHandle,
    showAlertBadge = false,
    counts = 0,
    isAdmin,
  }) => {
    var isSelected = path && currentPath && currentPath.startsWith(path)

    if (!childAllowedRoutes) {
      return (
        <BottomMenuLink
          title={title}
          icon={icon}
          isSelected={isSelected}
          existUnread={existUnread}
          onClickHandle={onClickHandle}
          showAlertBadge={showAlertBadge}
          counts={counts}
          isAdmin={isAdmin}
        />
      )
    }

    return (
      <>
        {childAllowedRoutes
          .filter(({ hideInSidebar }) => !hideInSidebar)
          .map(child => (
            <div key={child.path}>
              <BottomMenuLink
                key={child.path}
                title={child.title}
                icon={isSelected ? child.iconActive : child.iconInactive}
                isSelected={isSelected}
                existUnread={existUnread}
                onClickHandle={onClickHandle}
                isAdmin={isAdmin ? true : false}
              />
            </div>
          ))}
      </>
    )
  }

  function clickMenu(path) {
    setLoadingLocation(path)
  }

  const tabs = [
    {
      title: 'New offers',
      path: `/new-bookings`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Planned',
      path: `/planned`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Completed',
      path: `/completed`,
      hasAccess: true,
      isPrivate: false,
    },
  ]

  const tabsAccount = [
    {
      title: partnerType === 'rental' ? 'Agents' : 'Chauffeurs',
      path: `/chauffeurs`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Vehicles',
      path: `/cars`,
      hasAccess: true,
      isPrivate: false,
    },
    {
      title: 'Settings',
      path: `/settings`,
      hasAccess: true,
      isPrivate: false,
    },
  ]

  const routes = [
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/home-page`,
      title: 'Home',
      iconActive: 'icons/icon-home-black.png',
      iconInactive: 'icons/icon-home-grey.png',
      hideInSidebar: false,
    },
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/new-bookings`,
      title: 'Bookings',
      iconActive: 'icons/icon-bookings-black.png',
      iconInactive: 'icons/icon-bookings-grey.png',
      hideInSidebar: false,
    },
    {
      path: `${
        Host === partnerHostLive || Host === partnerHostStaging
          ? '/'
          : '/partner/'
      }driver/app/${token}/chauffeurs/account`,
      title: 'Account',
      iconActive: 'menu_account_active.png',
      iconInactive: 'menu_account_inactive.png',
      hideInSidebar: false,
    },
  ]

  useEffect(() => {
    if (whatsNewPartner) {
      setShowWhatsNew(true)
    }
  }, [whatsNewPartner])

  const onHide = async () => {
    setShowWhatsNew(false)
    await markPopUpAsSeenPartner()
  }

  const renderPopUp = () => (
    <WhatsNewModal
      show={showWhatsNew}
      onHide={onHide}
      values={whatsNewPartner}
    ></WhatsNewModal>
  )

  function onBackClick(e) {
    e.preventDefault()
    if (
      currentLocation.includes('details/chauffeur') ||
      currentLocation.includes('details/vehicle') ||
      currentLocation.includes('details/pickup') ||
      currentLocation.includes('details/confirm')
    ) {
      history.push(
        `${
          Host === partnerHostLive || Host === partnerHostStaging
            ? '/'
            : '/partner/'
        }driver/app/details/${token}/${bookingId}`
      )
    } else {
      // if (document.referrer === '') {
      //   history.push(
      //     `${
      //       Host === partnerHostLive || Host === partnerHostStaging
      //         ? '/'
      //         : '/partner/'
      //     }driver/app/${token}/planned`
      //   )
      // } else
      window.history.back()
    }
  }

  const tabsToNav = useMemo(
    () =>
      !tabs
        ? null
        : tabs
            .filter(tab => {
              return !!tab.hasAccess
            })
            .map(tab => ({
              ...tab,
              path: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${token}${tab.path}`,
            })),
    [tabs, match]
  )

  const tabsToNavAccount = useMemo(
    () =>
      !tabsAccount
        ? null
        : tabsAccount
            .filter(tab => {
              return !!tab.hasAccess
            })
            .map(tab => ({
              ...tab,
              path: `${
                Host === partnerHostLive || Host === partnerHostStaging
                  ? '/'
                  : '/partner/'
              }driver/app/${token}${tab.path}/account`,
            })),
    [tabsAccount, match]
  )

  const onHideBanner = async () => {
    await updatePartnerBanner(token)
    setShowBanner(false)
    await getPartnerDetails(token)
  }

  return (
    <div className='app-content app-content-partner'>
      <aside id='sidebar' className='sidebar-container-partner sidebar-white'>
        <div className={`sidebar-content`}>
          <SmallUp className='partner-sidebar'>
            {partnerName ? (
              <div className='company-name-dispatch mb-4'>{partnerName}</div>
            ) : (
              <div className='company-name-dispatch invisible mb-4'>
                COMPANY{' '}
              </div>
            )}
            <Link
              to='/booking/main/new-booking'
              className='switch-client-btn'
              style={{ marginBottom: '2.5rem' }}
            >
              <span>Switch to client</span>
              <img src='/images/icons/switch-gold.png'></img>
            </Link>
            <Nav className='flex-column sidebar-nav'>
              {routes
                .filter(({ hideInSidebar }) => !hideInSidebar)
                .map(({ path, ...rest }, i) => (
                  <MenuItem
                    key={String(i)}
                    path={path}
                    currentPath={location.pathname}
                    {...rest}
                  />
                ))}
              <a class='nav-link' style={{ cursor: 'pointer' }}>
                <div onClick={logout}>
                  <img
                    class='nav-link-img-partner'
                    src='/images/menu_logout.png'
                    alt=''
                  />
                </div>
                <span>
                  <div
                    onClick={logout}
                    style={{ display: 'flex', alignContent: 'center' }}
                  >
                    Log out
                  </div>
                </span>
              </a>
            </Nav>
          </SmallUp>
        </div>

        <div className={`partner-client-logo-wrap`}>
          <img src='/images/rolzo_logo_black.png' alt='' />
        </div>
      </aside>
      {/* page content */}
      <div className='page-wrap-partner page-wrap-light page-with-bottom-menu'>
        <div className='app-page'>
          <SmallUp>
            {!currentLocation.endsWith('home-page') &&
              !isBookingsSelected &&
              !isAccountSelected && (
                <div style={{ backgroundColor: 'rgba(139, 149, 158, 0.1)' }}>
                  <div className='supportbar-gap'>
                    <div
                      className='supportbar-back-btn-wrap'
                      onClick={e => {
                        onBackClick(e)
                      }}
                    >
                      <img
                        className='supportbar-back-img'
                        src='/images/icon-back-grey.png'
                        alt=''
                      />
                      <span className='supportbar-back-text'>Back</span>
                    </div>
                  </div>
                </div>
              )}
          </SmallUp>
          <div className='scrollable-page-content grey-theme'>
            <SmallUp>
              {' '}
              <div className='driverApp-notifications-wrap'>
                <NotificationsDriver
                  isPanel={true}
                  isExpanded={isNotificationExpanded}
                  closePanel={closePanel}
                />
              </div>
            </SmallUp>
            <nav className='navbar navbar-expand navbar-light'>
              <span className='navbar-brand'>
                {title && (
                  <header
                    className={
                      currentLocation.endsWith('home-page')
                        ? 'partner-header-home'
                        : 'partner-header'
                    }
                  >
                    {title &&
                    (title.includes('Booking') || title.includes('Offer')) ? (
                      <>
                        <h1 className='partner-page-title'>{title}</h1>
                        {details && !details.isPartner && data.driver ? (
                          <Badge
                            href='#'
                            variant={
                              data.status ===
                                'cancellation_accepted_by_agent' ||
                              data.status === 'cancelled_by_agent'
                                ? `cancelled-dispatch badge-outlined mt-1 ml-3`
                                : data.status === 'completed'
                                ? 'completed-dispatch badge-outlined mt-1 ml-3'
                                : `accepted-dispatch badge-outlined mt-1 ml-3`
                            }
                            style={{ display: 'flex', alignSelf: 'baseline' }}
                          >
                            {data.status === 'cancellation_accepted_by_agent' ||
                            data.status === 'cancelled_by_agent'
                              ? 'CANCELLED'
                              : data.status === 'completed'
                              ? 'COMPLETED'
                              : 'ACCEPTED'}
                          </Badge>
                        ) : (
                          [
                            //prettier-ignore
                            data && data.supplierInfo && data.supplierInfo.status && (
                              <Badge
                                href='#'
                                variant={
                                  data.status ===
                                    'cancellation_accepted_by_agent' ||
                                  data.status === 'cancelled_by_agent'
                                    ? `cancelled-dispatch badge-outlined mt-1 ml-3`
                                    : data.status === 'completed'
                                    ? 'completed-dispatch badge-outlined mt-1 ml-3'
                                    : isLocalSupplier && data.dispatchStatus === 'outsourced'
                                    ? 'outsourced-dispatch badge-outlined mt-1 ml-3' 
                                    : (data.supplierInfo.status === 'accepted' || (isLocalSupplier && data.dispatchStatus === 'accepted'))
                                    ? `accepted-dispatch badge-outlined mt-1 ml-3`
                                    : data.supplierInfo.status ===
                                        'dispatched' ||
                                      data.supplierInfo.status === 'opened'
                                    ? `new-booking badge-outlined mt-1 ml-3`
                                    : data.supplierInfo.status === 'expired' ||
                                      data.supplierInfo.status === 'declined'
                                    ? `expired-dispatch badge-outlined mt-1 ml-3`
                                    : ``
                                }
                                style={{
                                  display: 'flex',
                                  alignSelf: 'baseline',
                                }}
                              >
                                {data.status ===
                                  'cancellation_accepted_by_agent' ||
                                data.status === 'cancelled_by_agent'
                                  ? 'CANCELLED'
                                  : data.status === 'completed'
                                  ? 'COMPLETED'
                                  : (data.supplierInfo.status === 'accepted' || (isLocalSupplier && data.dispatchStatus === 'accepted'))
                                  ? 'ACCEPTED'
                                  : data.isOperativeBranch &&
                                    data.dispatchStatus === 'outsourced'
                                  ? 'OUTSOURCED'
                                  : data.supplierInfo.status === 'dispatched' ||
                                    data.supplierInfo.status === 'opened'
                                  ? 'PENDING'
                                  : data.supplierInfo.status === 'expired'
                                  ? `EXPIRED`
                                  : data.supplierInfo.status === 'declined'
                                  ? 'DECLINED'
                                  : ``}
                              </Badge>
                            ),
                          ]
                        )}
                        <a
                          href={`${
                            Host === partnerHostLive ||
                            Host === partnerHostStaging
                              ? '/'
                              : '/partner/'
                          }driver/app/${token}/${bookingId}/support`}
                          style={
                            elementWidth
                              ? {
                                  position: 'absolute',
                                  left: elementWidth,
                                  paddingLeft: '1em',
                                  display: 'flex',
                                  marginTop: '0.15rem',
                                }
                              : { display: 'none' }
                          }
                        >
                          <img
                            style={{ maxWidth: '25px', maxHeight: '25px' }}
                            src='/images/icons/support-icon.png'
                            alt=''
                          />
                        </a>
                      </>
                    ) : (
                      <>
                        <h1 className='partner-page-title'>{title}</h1>
                      </>
                    )}
                    {isCarRental &&
                      isChauffeur &&
                      currentLocation.endsWith('home-page') && (
                        <div className='partner-tabs'>
                          <div
                            className={`partner-tab-chauffeur ${
                              !currentPartnerType ||
                              currentPartnerType === 'chauffeur'
                                ? 'tab-active'
                                : 'tab-inactive'
                            }`}
                            onClick={() => {
                              togglePartnerType('chauffeur')
                            }}
                          >
                            Chauffeur service
                          </div>
                          <div
                            className={`partner-tab-rental  ${
                              currentPartnerType === 'rental'
                                ? 'tab-active'
                                : 'tab-inactive'
                            }`}
                            onClick={() => {
                              togglePartnerType('rental')
                            }}
                          >
                            Car rental
                          </div>
                        </div>
                      )}
                  </header>
                )}
              </span>
            </nav>
            <main style={!isMobileBrowser ? { paddingTop: '1em' } : {}}>
              {showBanner && isHomePageSelected ? (
                <div className='switch-client-banner'>
                  <div className='banner-header'>
                    <img
                      className='globe-icon'
                      src='/images/icons/globe-icon.png'
                    ></img>
                    <div>
                      <p> Grow Your Chauffeur Business Globally </p>
                      <p>
                        Get a 15% discounted affiliate rate and book chauffeur
                        services for your clients in more than 100 countries,
                        750 cities, and 1,000 airports.
                      </p>
                    </div>
                  </div>
                  <div className='right-div'>
                    <Link
                      to='/booking/main/new-booking'
                      className='switch-client-btn'
                    >
                      <span>Switch to client</span>
                      <img src='/images/icons/switch-gold.png'></img>
                    </Link>
                    <img
                      className='close-icon'
                      src='/images/icons/close-icon.png'
                      onClick={() => onHideBanner()}
                    ></img>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {renderContent()}
            </main>
          </div>
        </div>
      </div>
      <SmallDown>
        {details && details.isPartner && (
          <div className='sidebar-bottom-container sidebar-bottom-container-partner'>
            <BootMenuItem
              key='home'
              icon={
                isHomePageSelected
                  ? 'icons/icon-home-black.png'
                  : 'icons/icon-home-grey.png'
              }
              title='Home'
              onClickHandle={() => {
                clickMenu(
                  `${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/home-page`
                )
              }}
            />
            <hr className='driverBottomMenu' />
            <BootMenuItem
              key='booking'
              icon={
                isBookingsSelected
                  ? 'icons/icon-bookings-black.png'
                  : 'icons/icon-bookings-grey.png'
              }
              title='Bookings'
              onClickHandle={() => {
                clickMenu(
                  `${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/new-bookings`
                )
              }}
            />
            <hr className='driverBottomMenu' />
            <BootMenuItem
              key='account'
              icon={
                isAccountSelected
                  ? 'menu_account_active.png'
                  : 'menu_account_inactive.png'
              }
              title='Account'
              onClickHandle={
                details
                  ? () => {
                      clickMenu(
                        `${
                          Host === partnerHostLive ||
                          Host === partnerHostStaging
                            ? '/'
                            : '/partner/'
                        }driver/app/${token}/chauffeurs/account`
                      )
                    }
                  : {}
              }
            />
          </div>
        )}
      </SmallDown>
      {showWhatsNew &&
        whatsNewPartner &&
        !!whatsNewPartner.title &&
        !!whatsNewPartner.description &&
        renderPopUp()}
    </div>
  )
}

const mapStateToProps = ({
  auth: {
    isPartner = null,
    isDriver = null,
    isCarRental = null,
    isChauffeur = null,
    partnerName = null,
    isLocalSupplier = null,
    whatsNewPartner = null,
    showSwitchBanner = null,
  },
}) => ({
  isPartner,
  isDriver,
  isCarRental,
  isChauffeur,
  partnerName,
  isLocalSupplier,
  whatsNewPartner,
  showSwitchBanner,
})

export default withRouter(
  connect(mapStateToProps, {
    getPartnerDetails,
    logout,
    markPopUpAsSeenPartner,
    updatePartnerBanner,
  })(DefaultDriverDesktopLayout)
)
