import React from 'react'
import { Button, Image } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMessageClose } from '../actions'
import MatRaisedBtn from './MatRaisedBtn'

const SuccessMsg = ({
  className = null,
  alertData,
  setMessageClose,
  closeHandler,
  returnHandler,
  icon = '/images/icons/success_icon.png',
  isVerifyEmail = false,
  isRegisterEmail = false,
  isLogin = false,
  isPayment = false,
}) => {
  if (alertData === null) {
    return <></>
  }

  const onClose = () => {
    setMessageClose()
    if (closeHandler) {
      closeHandler()
    }
  }

  const onRenderReturn = () => {
    setMessageClose()
    if (returnHandler) {
      returnHandler()
    }
  }

  const title = alertData.title || 'Operation has executed success!'
  const message = alertData.message || ''
  const textLink = alertData.textLink || 'Go back to Dashboard'
  const returnText = alertData.return ? 'Book a return transfer' : null

  return (
    <div className={className ? className : 'success-msg-modal'}>
      <div className='content-wrap'>
        <Image src={icon} />
        <h4 className='success-msg-header'>{title}</h4>
        <p className='success-msg-description'>{message}</p>
        {returnText && (
          <div className='text-center'>
            <Button
              className='submit-btn'
              variant='brown'
              onClick={onRenderReturn}
            >
              {' '}
              {returnText}{' '}
            </Button>
          </div>
        )}

        {!isVerifyEmail && !isRegisterEmail && !isLogin && !isPayment && (
          <div className={returnText ? 'text-center mt-2' : 'text-center'}>
            <MatRaisedBtn title={textLink} onClick={onClose} />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ successMsg: { alertData } }) => {
  return {
    alertData,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { setMessageClose }
  )(SuccessMsg)
)
