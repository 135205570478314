import makeResponsiveComponent from './make-responsive-component'

export default makeResponsiveComponent(
  [
    {
      constraint: 'max',
      width: '768px',
      rules: `
          position: fixed;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 0 15px;
          background-color: #ffffff;
          border-top: 1px solid #f2f2f2;
        `
    },
  ]
)
