import {
  LOGGED_IN,
  LOGGED_OUT,
  SET_USER,
  SET_USER_COMPANY,
  SET_USER_IP_LOCATION,
  UPDATE_USER_INFO,
} from './types'

import {
  clearClientData,
  setClientCredentials,
  setMessageOpen,
  logger,
} from '../actions'

import { connectedApiService as api, connectedApiService } from '../index'
import { handleActionError } from './helpers'
import { showNotification } from './notifications'
import { createOrEditItem } from './form'
import { apiHost } from '../api'
import { comBillingFormData } from 'models/companyClient'

const aes256 = require('aes256')
const key = 'my passphrase'
const storedUserKey = 'RolzoCurrentUser'

export const setUser = payload => dispatch => {
  const json = payload ? JSON.stringify(payload) : null
  if (json) {
    localStorage.setItem(storedUserKey, json)

    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       action: 'updateStoredUser',
    //       data: { RolzoCurrentUser: json },
    //     })
    //   )
    // }

    const {
      authToken,
      userId,
      isLoggedIn,
      isMasqueradeUser,
      adminUserId,
      partnerToken,
    } = payload
    dispatch({
      type: LOGGED_IN,
      payload: {
        authToken,
        userId,
        isLoggedIn,
        isMasqueradeUser,
        adminUserId,
        partnerToken,
      },
    })
  } else {
    localStorage.removeItem(storedUserKey)
    dispatch({
      type: LOGGED_OUT,
    })
  }
  dispatch({
    type: SET_USER,
    payload,
  })
}

const setUserCompany = payload => ({
  type: SET_USER_COMPANY,
  payload,
})

const updateUserInfo = payload => ({
  type: UPDATE_USER_INFO,
  payload,
})

export const getDominapprove = credentials => async () => {
  try {
    const data = await api.post('getDomainInfo', credentials, false)
    return data
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const loginUser = credentials => async dispatch => {
  try {
    const {
      data: { userId, companyId, authToken, partnerToken, info = {} } = {},
    } = await api.post('login', credentials, false)
    dispatch(
      setUser({
        isLoggedIn: !!userId && !!authToken,
        authToken,
        userId,
        companyId,
        partnerToken,
        ...info,
      })
    )
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: 'login-user',
          data: {
            userId: userId,
            authToken: authToken,
            info: info,
            agent: navigator.userAgent,
          },
        })
      )
    }
    await dispatch(getUserCompanyInfo())
    return { partnerToken: partnerToken }
  } catch (error) {
    // console.log('loginUser error', error)
    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       action: 'login-error',
    //       data: { error: error.message },
    //     })
    //   )
    // }
    if (error[0].message === 'user email not varified') {
      await api.post('sendVerificationEmail', credentials, false)
      window.location = `/auth/mailSent/${credentials.email}`
    } else {
      dispatch(setUser())
      logger({
        fileName: 'auth',
        error: error,
      })
      return handleActionError(error)
    }
  }
}
export const loginClientUser = credentials => async dispatch => {
  try {
    const {
      data: { userId, companyId, authToken, partnerToken, info = {} } = {},
    } = await api.post('login', credentials, false)
    dispatch(
      setUser({
        isLoggedIn: !!userId && !!authToken,
        authToken,
        userId,
        companyId,
        partnerToken,
        ...info,
      })
    )
    await dispatch(getUserCompanyInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const registerClientUser = (credentials, history) => async dispatch => {
  try {
    dispatch(setClientCredentials(credentials))
    // dispatch(showNotification({ message: "You've successfully registered Client" }))
  } catch (error) {
    // dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const setClientUserDetails = details => async dispatch => {
  try {
    await api.post('client/register/', details, false)
    const { email, password, companyId } = details
    window.location = `/auth/mailSent/${email}`
  } catch (error) {
    // dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const getUserCompanyInfo = () => async (dispatch, getState) => {
  let company = null
  try {
    const { companyId } = getState().auth || {}
    if (companyId) {
      const { data } = await connectedApiService.get(
        `company/client/${companyId}`
      )
      if (data) {
        company = data
      }
    }
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    console.log(error)
  }
  dispatch(setUserCompany(company))
}

export const getUserInfo = () => async (dispatch, getState) => {
  try {
    const { userId, companyId } = getState().auth || {}
    if (userId && companyId) {
      const { data } = await connectedApiService.get(
        `company/client/${companyId}/user/${userId}`
      )

      if (data) {
        const {
          firstName,
          lastName,
          email,
          phone,
          whatsNew,
          whatsNewPartner,
          pricingModel,
          showComIndicator,
          showComPopup,
          savePassengerInfo,
        } = data

        dispatch(
          updateUserInfo({
            firstName,
            lastName,
            email,
            phone,
            whatsNew,
            pricingModel,
            showComIndicator,
            whatsNewPartner,
            showComPopup,
            savePassengerInfo,
          })
        )
      }
    }
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    console.log(error)
  }
}
export const verifyUserEmail = token => async dispatch => {
  try {
    const {
      data: { userId, companyId, authToken, info } = {},
    } = await dispatch(
      createOrEditItem(
        {
          token,
        },
        {
          endpoint: 'verifyUser',
          editRequest: 'post',
        }
      )
    )
    return {
      isLoggedIn: !!userId && !!authToken,
      userId,
      companyId,
      authToken,
      ...info,
    }
  } catch (err) {
    logger({
      fileName: 'auth',
      error: err,
    })
    return handleActionError(err)
  }
}
export const activateAccount = (
  { firstName, lastName, phone, email, password },
  token,
  isAccountActiveRequest = false
) => async dispatch => {
  try {
    const { data: { userId, companyId, authToken, info } = {} } = token
      ? await dispatch(
          createOrEditItem(
            {
              token,
              password,
              phone,
            },
            {
              endpoint: 'password-reset',
              editRequest: 'post',
            }
          )
        )
      : await dispatch(
          createOrEditItem(
            {
              firstName,
              lastName,
              phone,
              email,
              password,
            },
            {
              endpoint: 'password-reset',
              editRequest: 'post',
            }
          )
        )
    dispatch(
      showNotification({
        message: "You've successfully activated your account",
      })
    )
    // const email = info.email

    const { data: userInfo } = await dispatch(
      createOrEditItem(
        {
          firstName,
          lastName,
        },
        {
          isEdit: true,
          endpoint: `company/client/${companyId}/user/${userId}`,
          authToken,
          userId,
        }
      )
    )

    // dispatch(loginClientUser({ email, password }))
    if (isAccountActiveRequest) {
      return {
        isLoggedIn: !!userId && !!authToken,
        userId,
        companyId,
        authToken,
        ...info,
      }
    } else {
      dispatch(clearClientData())
      setTimeout(() => {
        // window.location = '/booking/main/'
        window.location = '/auth/login'
      }, 1000)
    }

    // await dispatch(getUserCompanyInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const checkAuth = (callback = () => {}) => async dispatch => {
  try {
    const storedUser = localStorage.getItem(storedUserKey)
    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       action: 'storedUser',
    //       data: { RolzoCurrentUser: storedUser, agent: navigator.userAgent },
    //     })
    //   )
    // }
    var data = null
    if (storedUser) {
      data = JSON.parse(storedUser)
    } else {
      var search = window.location.search
      var locationData = search.split('&')
      if (locationData) {
        var decoded = null
        locationData.forEach(element => {
          if (element.search('localtoken') !== -1) {
            decoded = element.split('=')[1]
          }
        })
        if (decoded) {
          if (JSON.parse(decodeURIComponent(decoded)) !== '0') {
            var uri_dec = JSON.parse(decodeURIComponent(decoded))
            var decrypted = aes256.decrypt(key, uri_dec)
            data = JSON.parse(decrypted)
          }
        }
      }
    }
    if (data) {
      if (data.isMasqueradeUser) {
        dispatch(setUser(data))
        await dispatch(getUserInfo())
        await dispatch(getUserCompanyInfo())
        callback()
      } else {
        const response = await dispatch(isTokenValid(data.authToken))
        if (response && response.data) {
          if (response.data.hasOwnProperty('tokenValid')) {
            if (response.data.tokenValid === true) {
              dispatch(setUser(data))
              await dispatch(getUserInfo())
              await dispatch(getUserCompanyInfo())
              callback()
            }
            if (response.data.tokenValid === false) {
              dispatch(setUser())
              callback()
            }
          }
        }
      }
    } else {
      dispatch(setUser())
      callback()
    }
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    callback()
  }
}

export const logout = () => async dispatch => {
  try {
    await api.post('logout', null, true)
  } catch (error) {
    console.log(error)
    logger({
      fileName: 'auth',
      error: error,
    })
  } finally {
    dispatch(setUser())
    dispatch(setUserCompany(null))
    window.parent.postMessage(
      {
        action: 'updateLocalStorage',
        data: '0',
      },
      '*'
    )
    // window.location.reload()
  }
}
export const getIpLocation = () => async dispatch => {
  try {
    const { data: { ipCountry } = {} } = await connectedApiService.get(
      `getIpLocation`
    )
    dispatch({
      type: SET_USER_IP_LOCATION,
      payload: {
        ipCountry,
      },
    })
    return ipCountry
  } catch (err) {
    console.log('getIpLocation', err)
  }
}
export const changePassword = ({ password }, token) => async dispatch => {
  try {
    const {
      data: { userId, companyId, authToken, info } = {},
    } = await dispatch(
      createOrEditItem(
        {
          token,
          password,
        },
        {
          endpoint: 'password-reset',
          editRequest: 'post',
        }
      )
    )
    dispatch(
      setUser({
        isLoggedIn: true,
        authToken,
        companyId,
        userId,
        info,
      })
    )

    await dispatch(getUserCompanyInfo())

    dispatch(
      showNotification({
        message: "You've successfully activated your account",
      })
    )
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const resetPassword = (token, password) => async dispatch => {
  try {
    await connectedApiService.post(
      'password-reset',
      {
        token,
        password,
      },
      false
    )
    dispatch(
      showNotification({
        message: "You've successfully reset password",
      })
    )
    setTimeout(() => {
      window.location = '/auth/login'
    }, 3000)
  } catch (error) {
    console.log(error)
    logger({
      fileName: 'auth',
      error: error,
    })
  }
}

export const getPrivateFileURL = fileId => async () => {
  let url = ''
  let ext = ''
  try {
    const { data: { token, extension } = {} } = await connectedApiService.get(
      `file/${fileId}/token`
    )
    if (token) {
      url = `${apiHost}/cdn/storage/files/${fileId}?token=${token}`
      ext = extension
    }
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  } finally {
    return {
      url: url,
      extension: ext,
    }
  }
}

export const getPrivatePartnerFileURL = fileId => async () => {
  let url = ''
  let ext = ''
  try {
    const { data: { token, extension } = {} } = await connectedApiService.get(
      `partner/file/${fileId}/token`
    )
    if (token) {
      url = `${apiHost}/cdn/storage/files/${fileId}?token=${token}`
      ext = extension
    }
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  } finally {
    return {
      url: url,
      extension: ext,
    }
  }
}

export const fcmNotification = registerData => async dispatch => {
  try {
    await dispatch(
      createOrEditItem(registerData, {
        endpoint: 'notification/fcm',
        editRequest: 'post',
      })
    )
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const fcmTokenUpdate = data => async dispatch => {
  try {
    await dispatch(
      createOrEditItem(data, {
        endpoint: 'notification/fcm',
        editRequest: 'put',
      })
    )
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const checkDevices = (userID, agent) => async () => {
  try {
    const data =
      (await api.post(`userDevices`, {
        userID,
        agent,
      })) || {}
    return data
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const isTokenValid = loginToken => async () => {
  try {
    const data =
      (await api.post(`checkAuthTokenValid`, {
        loginToken,
      })) || {}
    return data
  } catch (error) {
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const updateLastSeen = userId => async dispatch =>
  dispatch(
    createOrEditItem(
      {},
      {
        isEdit: false,
        endpoint: `updateLastSeen/${userId}`,
      }
    )
  )

export const markPopUpAsSeen = () => async (dispatch, getState) => {
  try {
    const { userId } = getState().auth || {}
    await connectedApiService.get(`whatsNew/${userId}`)
    await dispatch(getUserInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const markPopUpAsSeenPartner = () => async (dispatch, getState) => {
  try {
    const { userId } = getState().auth || {}
    await connectedApiService.get(`whatsNewPartner/${userId}`)
    await dispatch(getUserInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}

export const removeFCMToken = (userId, agent) => async dispatch =>
  dispatch(
    createOrEditItem(
      { userId, agent },
      { isEdit: true, endpoint: 'notification/fcm', editRequest: 'patch' }
    )
  )

export const updateComInfo = (userId, values) => async (dispatch, getState) => {
  try {
    let formattedData = await comBillingFormData(values)
    await connectedApiService.patch(`company/commission-details/${userId}`, {
      ...formattedData,
    })
    await dispatch(getUserInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}
export const hideComPopup = () => async (dispatch, getState) => {
  try {
    const { userId, companyId } = getState().auth || {}
    await connectedApiService.put(`company/commission-details/${companyId}`, {
      userId: userId,
    })
    await dispatch(getUserInfo())
  } catch (error) {
    dispatch(setUser())
    logger({
      fileName: 'auth',
      error: error,
    })
    return handleActionError(error)
  }
}
