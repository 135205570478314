import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'
import { components } from 'react-select'
import { getIn, setIn } from 'formik'
import { connectedApiService } from '../index'
import _ from 'lodash'
import { DropdownIndicator, MultiValueContainer } from './FormSelectComponents'

const { Control, Group, Label } = Form

export default function FormSelectAsync({
  field,
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  icon,
  groupProps = {},
  controlId,
  handleChange,
  isMulti = false,
  showSelectAll = false,
  isSearchable = false,
  disabled = false,
  endpoint = '',
  searchableParamKey = '',
  formatItems,
  limit = 30,
  defaultOptions,
  customFilter,
  customFilterChange,
  editPartnerCar = false,
  isRateCard = false,
  ...props
}) {
  const [searchableParamVal, setSearchableParamVal] = useState('')
  const [options, setOptions] = useState([])

  const getItems = useCallback(async () => {
    if (endpoint && !isRateCard) {
      const params = searchableParamKey
        ? `&filter[${searchableParamKey},like]=${searchableParamVal}`
        : ''
      var { data } =
        (await connectedApiService.get(
          `${endpoint}?page=1&limit=${limit}${params}`
        )) || {}
      if (editPartnerCar) {
        data = data.filter(el => {
          return el !== null
        })
      }
      if (customFilter) {
        data = customFilter(data)
      }
      const items = formatItems ? data.map(formatItems) : data
      if (!data.length && defaultOptions.length) {
        const defaultItems = formatItems
          ? defaultOptions.map(formatItems)
          : defaultOptions
        setOptions(defaultItems)
        return defaultItems
      }

      const uniqueValues = _.uniqBy(items, 'value')

      setOptions(uniqueValues)
      return uniqueValues
    } else if (isRateCard) {
      const response = await connectedApiService.get(endpoint)
      const uniqueValues = _.uniqBy(response.data, 'id')
      setOptions(uniqueValues)
      return uniqueValues
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endpoint,
    searchableParamVal,
    searchableParamKey,
    formatItems,
    limit,
    isRateCard,
  ])

  const showError = !!getIn(touched, field.name) && !!getIn(errors, field.name)

  const onChange = value => {
    setFieldValue(field.name, value)
    if (handleChange) {
      handleChange(value)
    }
  }
  const onInputChange = val => {
    setSearchableParamVal(val)
  }

  const onSelectAll = e => {
    e.preventDefault()
    e.stopPropagation()
    const newValue =
      !field.value || field.value.length < options.length ? [...options] : []
    setFieldValue(field.name, newValue)
  }

  const onBlur = () => {
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }

  const ValueContainer = isMulti
    ? MultiValueContainer
    : components.ValueContainer
  const Option = props => {
    if (props.data.type && props.data.type === 'globalRateVehicle') {
      return (
        <div className='selectOptionButton'>
          <components.Option {...props}>
            <div style={{ display: 'inlineBlock' }}>
              <span>{props.data.label}</span>
              <div style={{ float: 'right' }}>
                <span className='pr-1 vehicle-passenger-select-Async'>
                  {props.data.passenger}
                </span>
                <span className='pr-1 vehicle-luggage-select-Async'>
                  {props.data.luggage}
                </span>
              </div>
            </div>
          </components.Option>
        </div>
      )
    } else {
      return <components.Option {...props} />
    }
  }
  return (
    <Group controlId={controlId || field.name}>
      <Label className='form-label'>{props.label}</Label>
      <AsyncSelect
        components={{ DropdownIndicator, ValueContainer, Option }}
        className={`react-select-container ${showError ? 'is-invalid' : ''}`}
        classNamePrefix='react-select'
        {...field}
        cacheOptions
        defaultOptions
        inputId={controlId || field.name}
        onBlur={onBlur}
        onChange={onChange}
        onInputChange={onInputChange}
        // loadOptions={isRateCard ? cardOptions : getItems}
        loadOptions={getItems}
        isSearchable={isSearchable}
        isDisabled={disabled}
        key={`${String(endpoint)}${customFilterChange}`}
        isMulti={isMulti}
        hideSelectedOptions={!isMulti}
        onSelectAll={showSelectAll ? onSelectAll : null}
        selectedAll={
          !!showSelectAll &&
          !!isMulti &&
          !!field.value &&
          field.value.length === options.length
        }
        // menuIsOpen
        options={options}
        getOptionLabel={option => {
          if (props.label === 'CAR' || props.label === 'Car') {
            if (option.label && option.value) {
              return (
                <div className='selectOptionButton'>
                  <div style={{ display: '' }}>
                    <span>{`${option.label}`} </span>
                    <div style={{ float: 'right' }}>
                      <span className='pr-1 vehicle-passenger-select-Async'>
                        {option.passenger}
                      </span>
                      <span className='pr-1 vehicle-luggage-select-Async'>
                        {option.luggage}
                      </span>
                    </div>
                  </div>
                </div>
              )
            }
          } else {
            return <span>{`${option.label}`} </span>
          }
        }}
      />
      {showError && (
        <Control.Feedback className='d-block' type='invalid'>
          {getIn(errors, field.name)}
        </Control.Feedback>
      )}
    </Group>
  )
}
