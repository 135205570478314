import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { defaultVehicleCategories, defaultCurrencies } from '../constants'
import {
  getLabelFromValue,
  numberWithCommas,
  getFormattedPrice,
  isMobile,
  getGlobalCancellationPolicy,
} from '../helpers'
import FormCheckbox from './FormCheckbox'
import FormField from './FormField'
import { SmallUp, SmallDown } from './Responsive/breakpoints'
import { CLOSE_CAR_DROP_DOWN, OPEN_CAR_DROP_DOWN } from '../actions'
import LocationType from './locationType'
import moment from 'moment'
import { template } from 'lodash'

const isMobileBrowser = isMobile()

const VehicleValue = ({ value, icon = 'team' }) => {
  return (
    <span className='vehicle-value'>
      <img src={`/images/icons/${icon}.svg`} alt='' /> {value}
    </span>
  )
}
const IsElectric = ({ className }) => {
  return (
    <span className={`${className ? className : ''} electric-vehicle mb-0`}>
      ELECTRIC
      {/* <img src={`/images/icons/ROLZO_leaf_badge_EV.png`} alt='' /> */}
    </span>
  )
}

const VehicleCard = ({
  id,
  key,
  className = '',
  src,
  category,
  markupWeight,
  title,
  buttonTitle,
  handler,
  passangersCapacity,
  lugageCapacity,
  price,
  continent,
  minHours = null,
  globalRateId = null,
  globalRateLocationId = null,
  durationType,
  actualRate = null,
  discountWeight = null,
  paymentType = null,
  extraMilePrice = null,
  dailyMileageUnits = null,
  depositPrice = null,
  currency,
  depositCurrency = null,
  priceDescription,
  years,
  selected = false,
  checkbox = false,
  checked = false,
  onCheck,
  booked = false,
  status,
  isEditablePrice = false,
  onPriceChange,
  onDepositChange,
  isAdmin = false,
  type,
  searchValues = null,
  isDropDown = false,
  isDetailsDropDown = false,
  setDetailsExpanded,
  detailsExpanded,
  active,
  isExpanded,
  electricVehicle = false,
  benefits = null,
  memberBenefits = null,
  isQuoteSearchAdmin = false,
  isQuoteCarRental = false,
  deposit = null,
  extraMile = null,
  dailyMileage = null,
  showCheckbox = true,
  isReturnVehicle = null,
  isActive = false,
  showWarningBanner = false,
  cancellationPolicy,
  thereIsGlobalPolicy,
  globalCancellationPolicy = {},
  disabled = false,
  arrival = null,
  departure = null,
  isQuotePax = false,
  isQuoteModel = false,
  isQuoteSearchValues = null,
  tripInfo,
  onChangePolicy,
  vehicleHourlyRate,
  isViewQuote = false,
  hideCommIcon = false,
  openCommissionModal = () => {},
  isRolzoFirstCompany = false,
}) => {
  const [newPrice, setNewPrice] = useState('')
  const [originalPrice, setOriginalPrice] = useState('')
  const [markupPrice, setMarkupPrice] = useState(0)
  const [expanded, setExpanded] = useState(isExpanded)
  const [editedActualRate, setEditedActualRate] = useState(null)
  const [memberBenefitsCurrency, setMemberBenefitsCurrency] = useState('')
  const [changedRates, setChangedRates] = useState({})
  const [isAirport, setIsAirport] = useState(null)
  const [isPort, setIsPort] = useState(null)
  const [isMoreThanTwoHours, setIsMoreThanTwoHours] = useState(false)
  const [cancelPolicy, setCancelPolicy] = useState('12 hours')
  const [vRate, setVRate] = useState(
    vehicleHourlyRate && vehicleHourlyRate.rateOnly
      ? parseFloat(vehicleHourlyRate.originalRate).toFixed(2)
      : 0
    // vehicleHourlyRate && vehicleHourlyRate.rateOnly
    //   ? parseFloat(vehicleHourlyRate.rateOnly).toFixed(2)
    //   : 0
  )
  const [chargedVRate, setChargedVRate] = useState(() => {
    return vehicleHourlyRate && vehicleHourlyRate.discount
      ? (
          ((100 - vehicleHourlyRate.discount) / 100) *
          vehicleHourlyRate.originalRate
        ).toFixed(2)
      : vRate
    // return vehicleHourlyRate && vehicleHourlyRate.discount
    //   ? (
    //       ((100 - vehicleHourlyRate.discount) / 100) *
    //       vehicleHourlyRate.rateOnly
    //     ).toFixed(2)
    //   : vRate
  })

  const dispatch = useDispatch()
  useEffect(() => {}, [checked])
  const displayCurrency = useMemo(
    () => getLabelFromValue(defaultCurrencies, currency),
    [currency]
  )
  const depositDefaultCurrency = useMemo(() => {
    if (depositCurrency) {
      return getLabelFromValue(defaultCurrencies, depositCurrency)
    }
  }, [depositCurrency])
  const displayCategory = useMemo(
    () => getLabelFromValue(defaultVehicleCategories, category),
    [category]
  )
  const onGetLocation = async () => {
    let locationType =
      searchValues &&
      searchValues.pickUpLocation &&
      !searchValues.pickUpLocation.locationType
        ? await LocationType(searchValues.pickUpLocation)
        : null
    setIsAirport(
      searchValues &&
        searchValues.pickUpLocation &&
        searchValues.pickUpLocation.locationType &&
        searchValues.pickUpLocation.locationType === 'isAirport'
        ? true
        : locationType && locationType.pickup
        ? locationType.pickup.isAirport
        : null
    )

    setIsPort(
      searchValues &&
        searchValues.pickUpLocation &&
        searchValues.pickUpLocation.locationType &&
        searchValues.pickUpLocation.locationType === 'isPort'
        ? true
        : locationType && locationType.pickup
        ? locationType.pickup.isPort
        : null
    )
  }
  useEffect(() => {
    if (searchValues) {
      onGetLocation()
    }
  }, [searchValues])
  useEffect(() => {
    if (markupWeight > 0 && markupPrice === 0) {
      setMarkupPrice((actualRate * markupWeight) / 100)
    }
  }, [markupWeight, markupPrice])
  useEffect(() => {
    if (memberBenefits) {
      setMemberBenefitsCurrency(
        getLabelFromValue(defaultCurrencies, memberBenefits.currency)
      )
    }
    if (price) {
      if (benefits) {
        setOriginalPrice(
          (parseFloat(price) / (1 - benefits.discountWeight / 100)).toFixed(2)
        )
      } else {
        setOriginalPrice(
          (parseFloat(price) / (1 - discountWeight / 100)).toFixed(2)
        )
      }
      setNewPrice(price)
      if (deposit && extraMile && dailyMileage) {
        setChangedRates({
          deposit: Number(deposit).toFixed(2),
          extraMile: Number(extraMile).toFixed(2),
          dailyMileage: Number(dailyMileage).toFixed(2),
        })
      } else if (deposit) {
        setChangedRates({
          deposit: deposit.toFixed(2),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, memberBenefits])

  useEffect(() => {
    if (tripInfo && tripInfo.duration) {
      const duration = moment.duration(tripInfo.duration, 'seconds')
      const numHours = duration.hours()
      const numMinutes = duration.minutes()
      if (numHours > 2 || (numHours === 2 && numMinutes > 0)) {
        setIsMoreThanTwoHours(true)
      } else {
        setIsMoreThanTwoHours(false)
      }
    }
  }, [tripInfo?.duration])

  useEffect(() => {
    if (isQuoteSearchValues && isQuoteSearchValues.mode) {
      if (isQuoteSearchValues.mode.value === 'transfer') {
        if (showWarningBanner && cancellationPolicy.transferPolicy) {
          setCancelPolicy(cancellationPolicy.transferPolicy)
        } else if (isMoreThanTwoHours) {
          setCancelPolicy('24 hours')
        } else if (
          globalCancellationPolicy.globalCitiesPolicy &&
          globalCancellationPolicy.citiesTransferPolicy &&
          globalCancellationPolicy.globalCitiesPolicy.some(
            item => item.city === isQuoteSearchValues.pickUpLocation.city
          )
        ) {
          setCancelPolicy(globalCancellationPolicy.citiesTransferPolicy)
        } else if (globalCancellationPolicy.globalTransferPolicy) {
          setCancelPolicy(globalCancellationPolicy.globalTransferPolicy)
        } else if (vehicleTypesPolicy.includes(category)) {
          setCancelPolicy('48 hours')
        } else if (category === 'van') {
          setCancelPolicy('24 hours')
        } else {
          setCancelPolicy('4 hours')
        }
      } else if (isQuoteSearchValues.mode.value === 'chauffeurService') {
        if (durationType && durationType === 'hourly') {
          if (showWarningBanner && cancellationPolicy.hourlyPolicy) {
            setCancelPolicy(cancellationPolicy.hourlyPolicy)
          } else if (
            globalCancellationPolicy.globalCitiesPolicy &&
            globalCancellationPolicy.citiesHourlyPolicy &&
            globalCancellationPolicy.globalCitiesPolicy.some(
              item => item.city === isQuoteSearchValues.pickUpLocation.city
            )
          ) {
            setCancelPolicy(globalCancellationPolicy.citiesHourlyPolicy)
          } else if (globalCancellationPolicy.globalHourlyPolicy) {
            setCancelPolicy(globalCancellationPolicy.globalHourlyPolicy)
          } else if (vehicleTypesPolicy.includes(category)) {
            setCancelPolicy('48 hours')
          } else if (category === 'van') {
            setCancelPolicy('24 hours')
          } else {
            setCancelPolicy('12 hours')
          }
        } else {
          if (showWarningBanner && cancellationPolicy.hourlyPolicy) {
            // daily and hourly have same one
            setCancelPolicy(cancellationPolicy.hourlyPolicy)
          } else if (
            globalCancellationPolicy.globalCitiesPolicy &&
            globalCancellationPolicy.citiesDailyPolicy &&
            globalCancellationPolicy.globalCitiesPolicy.some(
              item => item.city === isQuoteSearchValues.pickUpLocation.city
            )
          ) {
            setCancelPolicy(globalCancellationPolicy.citiesDailyPolicy)
          } else if (globalCancellationPolicy.globalHourlyPolicy) {
            setCancelPolicy(globalCancellationPolicy.globalHourlyPolicy)
          } else if (vehicleTypesPolicy.includes(category)) {
            setCancelPolicy('48 hours')
          } else {
            // no need to check for van cause van and default hours are the same for daily
            setCancelPolicy('24 hours')
          }
        }
      } else if (isQuoteSearchValues.mode.value === 'carRental') {
        if (showWarningBanner && cancellationPolicy.carRentalPolicy) {
          setCancelPolicy(cancellationPolicy.carRentalPolicy)
        } else if (
          globalCancellationPolicy.globalCitiesPolicy &&
          globalCancellationPolicy.citiesCarRentalPolicy &&
          globalCancellationPolicy.globalCitiesPolicy.some(
            item => item.city === isQuoteSearchValues.pickUpLocation.city
          )
        ) {
          setCancelPolicy(globalCancellationPolicy.citiesCarRentalPolicy)
        } else if (globalCancellationPolicy.globalCarRentalPolicy) {
          setCancelPolicy(globalCancellationPolicy.globalCarRentalPolicy)
        } else {
          setCancelPolicy('48 hours')
        }
      }
    } else if (isViewQuote) {
      if (cancellationPolicy) {
        setCancelPolicy(cancellationPolicy.cancellation)
      }
    }
  }, [isQuoteSearchValues])

  const renderMinimumHours = (minHours, searchValues) => {
    var searchHours = null
    if (searchValues.mode.value === 'chauffeurService') {
      if (durationType && durationType === 'hourly') {
        searchHours = searchValues.hours.value
        return (
          searchHours &&
          minHours &&
          minHours > searchHours &&
          durationType &&
          durationType !== 'daily' &&
          (isAdmin ? (
            <div className='hint-text'>Minimum {minHours} hours</div>
          ) : (
            <div
              className='hint-text'
              style={{ marginTop: '-5px', paddingBottom: '5px' }}
            >
              Minimum {minHours} hours
            </div>
          ))
        )
      }
    }
  }
  const selectVehicle = () => {
    if (handler) handler()
  }
  const isGreaterThanZero = (actualRate, discountWeight) => {
    return (actualRate * discountWeight) / 100 > 0
  }
  const updateRates = value => {
    if (markupWeight > 0) {
      setMarkupPrice(
        (parseFloat(
          (parseFloat(value) / (1 - benefits.discountWeight / 100)).toFixed(2)
        ) *
          markupWeight) /
          100
      )
    }
    if (benefits) {
      setOriginalPrice(
        (parseFloat(value) / (1 - benefits.discountWeight / 100)).toFixed(2)
      )
      setEditedActualRate(
        parseFloat(value) / (1 - benefits.discountWeight / 100)
      )
      onPriceChange(
        parseFloat(value),
        parseFloat(value) / (1 - benefits.discountWeight / 100)
      )
    } else if (paymentType === 'rate_type_net') {
      setOriginalPrice(
        (parseFloat(value) / (1 - discountWeight / 100)).toFixed(2)
      )
      setEditedActualRate(parseFloat(value) / (1 - discountWeight / 100))
      onPriceChange(
        parseFloat(value),
        parseFloat(value) / (1 - discountWeight / 100)
      )
    } else {
      setEditedActualRate(value)
    }
  }
  const updateRatesOriginal = value => {
    let originalPrice = value
    if (markupWeight > 0) {
      value = parseFloat(value) - (parseFloat(value) * markupWeight) / 100
      setMarkupPrice((parseFloat(originalPrice) * markupWeight) / 100)
    }
    // setOriginalPrice(value)
    if (benefits) {
      if (markupWeight > 0) {
        value =
          parseFloat(value) + (parseFloat(originalPrice) * markupWeight) / 100
      }
      setNewPrice(
        (value - parseFloat((value * benefits.discountWeight) / 100)).toFixed(2)
      )
      onPriceChange(
        (value - parseFloat((value * benefits.discountWeight) / 100)).toFixed(
          2
        ),
        value
      )
      setEditedActualRate(value)
    } else if (paymentType === 'rate_type_net') {
      if (markupWeight > 0) {
        value =
          parseFloat(value) + (parseFloat(originalPrice) * markupWeight) / 100
      }
      setNewPrice(
        (value - parseFloat((value * discountWeight) / 100)).toFixed(2)
      )
      onPriceChange(
        (value - parseFloat((value * discountWeight) / 100)).toFixed(2),
        value
      )
      setEditedActualRate(value)
    } else {
      if (markupWeight > 0) {
        value =
          parseFloat(value) + (parseFloat(originalPrice) * markupWeight) / 100
      }
      setEditedActualRate(value)
    }
  }

  const updateVRate = val => {
    var rate = parseFloat(val)
    var offset = vehicleHourlyRate.offset
      ? parseFloat(vehicleHourlyRate.offset)
      : 0
    if (benefits || paymentType === 'rate_type_net') {
      let discount = benefits.discountWeight
        ? benefits.discountWeight
        : discountWeight
      let temp = 1 - discount / 100
      rate = (parseFloat(val) / temp).toFixed(2)
      offset = rate - val
    }
    // if (benefits) {
    //   let temp = 1 - benefits.discountWeight / 100
    //   rate = (parseFloat(val) / temp).toFixed(2)
    // } else if (paymentType === 'rate_type_net') {
    //   let temp = 1 - discountWeight / 100
    //   rate = (parseFloat(val) / temp).toFixed(2)
    // } else {
    //   rate = parseFloat(val)
    // }
    // offset: "19.50"
    // originalRate: 195
    // rate: "100.00 AED"
    // rateOnly: 100
    vehicleHourlyRate.offset = parseFloat(offset).toFixed(2)
    vehicleHourlyRate.originalRate = parseFloat(parseFloat(rate).toFixed(2))
    vehicleHourlyRate.rateOnly = parseFloat(parseFloat(val).toFixed(2))
    vehicleHourlyRate.rate = `${parseFloat(val).toFixed(2)} ${displayCurrency}`
    // vehicleHourlyRate.rateOnly = parseFloat(parseFloat(rate).toFixed(2))
    // vehicleHourlyRate.rate = `${parseFloat(rate).toFixed(2)} ${displayCurrency}`
  }

  const updateChargedVRate = val => {
    // original vehicleHourlyRate
    // discount: 10
    // offset: "19.50" discount value
    // originalRate: 195 original calue
    // rate: "175.50 AED" discounted value
    // rateOnly: 175.5 discounted value
    var newChargeRate = parseFloat(val)
    var offset = vehicleHourlyRate.offset
      ? parseFloat(vehicleHourlyRate.offset)
      : 0
    if (benefits || paymentType === 'rate_type_net') {
      let discount = benefits.discountWeight
        ? benefits.discountWeight
        : discountWeight
      let temp = (100 - discount) / 100
      newChargeRate = (temp * parseFloat(val)).toFixed(2)
      offset = val - newChargeRate
    }
    setChargedVRate(newChargeRate)
    // if (benefits) {
    //   let temp = (100 - benefits.discountWeight) / 100
    //   setChargedVRate((temp * parseFloat(val)).toFixed(2))
    // } else if (paymentType === 'rate_type_net') {
    //   let temp = (100 - discountWeight) / 100
    //   setChargedVRate((temp * parseFloat(val)).toFixed(2))
    // } else {
    //   setChargedVRate(parseFloat(val))
    // }
    debugger
    // offset: "19.50"
    // originalRate: 195
    // rate: "100.00 AED"
    // rateOnly: 100
    vehicleHourlyRate.offset = parseFloat(offset).toFixed(2)
    vehicleHourlyRate.originalRate = parseFloat(parseFloat(val).toFixed(2))
    vehicleHourlyRate.rateOnly = parseFloat(
      parseFloat(newChargeRate).toFixed(2)
    )
    vehicleHourlyRate.rate = `${parseFloat(newChargeRate).toFixed(
      2
    )} ${displayCurrency}`
    // vehicleHourlyRate.rateOnly = parseFloat(parseFloat(val).toFixed(2))
    // vehicleHourlyRate.rate = `${parseFloat(val).toFixed(2)} ${displayCurrency}`
  }

  const vehicleTypesPolicy = ['coach', 'luxe', 'minibus', 'stretch']
  return (
    <>
      <SmallUp>
        <Card
          className={`${
            className === 'chat-vehicle-card'
              ? !status || status === 'quote_in_progress'
                ? 'vehicle-card-desktop chat-vehicle-card vehicle-card-noclick'
                : `vehicle-card-desktop  ${className} ${active}`
              : isReturnVehicle
              ? 'vehicle-card vehicle-card-selected'
              : isActive
              ? 'vehicle-card active'
              : 'vehicle-card'
          }  `}
          onClick={() =>
            className === 'chat-vehicle-card' &&
            status &&
            status !== 'quote_in_progress'
              ? selectVehicle()
              : className !== 'chat-vehicle-card' &&
                className !== '' &&
                className !== 'car-rent' &&
                !status
              ? selectVehicle()
              : {}
          }
          style={{ flexWrap: 'wrap' }}
        >
          {className === 'chat-vehicle-card' ? (
            <>
              <div className='d-flex pb-3 mb-2'>
                <div className='vehicle-img-wrap'>
                  <img src={src} className='vehicle-img' alt='' />
                </div>
                {isQuoteModel && !isQuoteCarRental && (
                  <FormField
                    field={{
                      name: `name-cancellation-policy`,
                      value: cancelPolicy,
                      onChange: evt => {
                        setCancelPolicy(evt.target.value)
                        onChangePolicy(evt.target.value)
                      },
                    }}
                    label='cancellation policy'
                  />
                )}
                {(isMobileBrowser || !isQuotePax) && (
                  <div
                    className='d-flex align-items-center'
                    style={{ marginBottom: '0.8rem' }}
                  >
                    {passangersCapacity && passangersCapacity !== 0 ? (
                      <VehicleValue value={passangersCapacity} icon='team' />
                    ) : (
                      <></>
                    )}

                    {lugageCapacity && lugageCapacity !== 0 ? (
                      <VehicleValue value={lugageCapacity} icon='portfolio' />
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </div>

              <div className='card-body card-body-mobile'>
                <h5
                  className={
                    electricVehicle
                      ? 'sub-title-electric sub-title'
                      : 'sub-title'
                  }
                >
                  {electricVehicle ? <IsElectric /> : displayCategory}
                </h5>

                {priceDescription ? (
                  <div className='hint-text'>{priceDescription}</div>
                ) : (
                  ''
                )}
              </div>
              <div className='d-flex'>
                <Card.Title
                  className={
                    electricVehicle
                      ? 'vehicle-title electric-vehicle-title'
                      : 'vehicle-title'
                  }
                >
                  {title}
                </Card.Title>
                <Col className='card-action col ml-2' xs='auto'>
                  <>
                    <div
                      className={`lead ${
                        isEditablePrice ? 'editable-vehicle-rate' : ''
                      }`}
                    >
                      {isEditablePrice ? (
                        <FormField
                          field={{
                            name: `name-price-${title}`,
                            value: newPrice,
                            onChange: evt => {
                              setNewPrice(evt.target.value)
                              onPriceChange(evt.target.value, 'a')
                            },
                          }}
                        />
                      ) : (
                        numberWithCommas(getFormattedPrice(price))
                      )}
                      {` ${displayCurrency}`}{' '}
                      {priceDescription && type === 'car-rent' && (
                        <span>
                          /<span className='font-12'>day</span>
                        </span>
                      )}
                      {!isAdmin && (
                        <>
                          {memberBenefits ? (
                            <>
                              {actualRate &&
                              benefits &&
                              !hideCommIcon &&
                              (benefits.commissionWeight ||
                                benefits.discountWeight) ? (
                                <>
                                  <img
                                    alt=''
                                    className='info-icon'
                                    src='/images/icons/icon-information.png'
                                    onClick={() => {
                                      openCommissionModal(
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.commissionWeight
                                        )
                                          ? numberWithCommas(
                                              parseFloat(
                                                (actualRate *
                                                  benefits.commissionWeight) /
                                                  100
                                              ).toFixed(2)
                                            )
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.commissionWeight
                                        )
                                          ? benefits.commissionWeight
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.discountWeight
                                        )
                                          ? numberWithCommas(
                                              parseFloat(
                                                (actualRate *
                                                  benefits.discountWeight) /
                                                  100
                                              ).toFixed(2)
                                            )
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.discountWeight
                                        )
                                          ? {
                                              weight: benefits.discountWeight,
                                              code: benefits.code,
                                            }
                                          : null,
                                        displayCurrency
                                      )
                                    }}
                                  ></img>
                                </>
                              ) : (
                                <>
                                  {actualRate &&
                                    discountWeight >= 0 &&
                                    paymentType &&
                                    paymentType !== 'rate_type_net' &&
                                    !hideCommIcon && (
                                      <>
                                        {isGreaterThanZero(
                                          actualRate,
                                          discountWeight
                                        ) ? (
                                          <img
                                            alt=''
                                            className='info-icon'
                                            src='/images/icons/icon-information.png'
                                            onClick={() => {
                                              openCommissionModal(
                                                isGreaterThanZero(
                                                  actualRate,
                                                  discountWeight
                                                )
                                                  ? numberWithCommas(
                                                      parseFloat(
                                                        (actualRate *
                                                          discountWeight) /
                                                          100
                                                      ).toFixed(2)
                                                    )
                                                  : null,
                                                discountWeight,
                                                null,
                                                null,
                                                displayCurrency
                                              )
                                            }}
                                          ></img>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {actualRate &&
                              benefits &&
                              !hideCommIcon &&
                              (benefits.commissionWeight ||
                                benefits.discountWeight) ? (
                                <>
                                  <img
                                    className='info-icon'
                                    src='/images/icons/icon-information.png'
                                    alt=''
                                    onClick={() => {
                                      openCommissionModal(
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.commissionWeight
                                        )
                                          ? numberWithCommas(
                                              parseFloat(
                                                (actualRate *
                                                  benefits.commissionWeight) /
                                                  100
                                              ).toFixed(2)
                                            )
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.commissionWeight
                                        )
                                          ? benefits.commissionWeight
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.discountWeight
                                        )
                                          ? numberWithCommas(
                                              parseFloat(
                                                (actualRate *
                                                  benefits.discountWeight) /
                                                  100
                                              ).toFixed(2)
                                            )
                                          : null,
                                        isGreaterThanZero(
                                          actualRate,
                                          benefits.discountWeight
                                        )
                                          ? {
                                              weight: benefits.discountWeight,
                                              code: benefits.code,
                                            }
                                          : null,
                                        displayCurrency
                                      )
                                    }}
                                  ></img>
                                </>
                              ) : (
                                <>
                                  {actualRate &&
                                    discountWeight > 0 &&
                                    paymentType !== 'rate-type-net' &&
                                    !hideCommIcon && (
                                      <>
                                        {isGreaterThanZero(
                                          actualRate,
                                          discountWeight
                                        ) ? (
                                          <img
                                            className='info-icon'
                                            src='/images/icons/icon-information.png'
                                            alt=''
                                            onClick={() => {
                                              openCommissionModal(
                                                numberWithCommas(
                                                  parseFloat(
                                                    (actualRate *
                                                      discountWeight) /
                                                      100
                                                  ).toFixed(2)
                                                ),
                                                discountWeight,
                                                null,
                                                null,
                                                displayCurrency
                                              )
                                            }}
                                          ></img>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                </Col>
              </div>
              <div className='d-flex'>
                <Col className='card-action col ml-2' xs='auto'>
                  <>
                    {durationType &&
                    durationType === 'hourly' &&
                    vehicleHourlyRate ? (
                      <span className='text-grey-sub-small text-incl-grey-2'>
                        {`Extra hour: ${numberWithCommas(
                          parseFloat(chargedVRate).toFixed(2)
                        )} ${displayCurrency}`}
                      </span>
                    ) : (
                      <></>
                    )}
                    {memberBenefits ? (
                      <>
                        <span className='text-grey-sub-small  text-incl-grey-2 '>
                          {'-'} {memberBenefits.discountApplied}{' '}
                          {`${displayCurrency} `}
                          {memberBenefits.type === 'fixedDiscount' ? (
                            <>
                              {memberBenefitsCurrency !== displayCurrency
                                ? `(${numberWithCommas(
                                    memberBenefits.amount
                                  )} ${getLabelFromValue(
                                    defaultCurrencies,
                                    memberBenefits.currency
                                  )}) discount applied`
                                : 'discount applied'}
                            </>
                          ) : (
                            ` (${numberWithCommas(
                              memberBenefits.amount
                            )} %) discount applied`
                          )}
                        </span>
                        {isAdmin && (
                          <>
                            {actualRate && benefits ? (
                              <>
                                {isGreaterThanZero(
                                  actualRate,
                                  benefits.commissionWeight
                                ) ? (
                                  <span className='text-grey-sub-small '>
                                    {'Incl. '}
                                    {numberWithCommas(
                                      parseFloat(
                                        (actualRate *
                                          benefits.commissionWeight) /
                                          100
                                      ).toFixed(2)
                                    )}{' '}
                                    {displayCurrency} {'commission'}{' '}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <>
                                {actualRate &&
                                  discountWeight >= 0 &&
                                  paymentType &&
                                  paymentType !== 'rate_type_net' && (
                                    <>
                                      {isGreaterThanZero(
                                        actualRate,
                                        discountWeight
                                      ) ? (
                                        <span className='text-grey-sub-small '>
                                          {''}{' '}
                                          {parseFloat(
                                            (actualRate * discountWeight) / 100
                                          ).toFixed(2)}{' '}
                                          {displayCurrency} {'commission'}{' '}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {actualRate && benefits ? (
                          <>
                            {/* {isGreaterThanZero(
                              actualRate,
                              benefits.discountWeight
                            ) ? (
                              <span className='text-grey-sub-small  text-incl-grey-2'>
                                {'- '}
                                {parseFloat(
                                  (actualRate * benefits.discountWeight) / 100
                                ).toFixed(2)}{' '}
                                {displayCurrency} {'discount applied'}{' '}
                              </span>
                            ) : (
                              <></>
                            )} */}
                            {isAdmin && (
                              <>
                                {isGreaterThanZero(
                                  actualRate,
                                  discountWeight
                                ) ? (
                                  <span className='text-grey-sub-small '>
                                    {paymentType !== 'rate_type_net'
                                      ? 'Incl.'
                                      : ''}
                                    {'-'}{' '}
                                    {numberWithCommas(
                                      parseFloat(
                                        (actualRate * discountWeight) / 100
                                      ).toFixed(2)
                                    )}{' '}
                                    {displayCurrency}{' '}
                                    {paymentType === 'rate_type_net'
                                      ? 'discount applied'
                                      : 'commission'}{' '}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                            {isAdmin && (
                              <>
                                {isGreaterThanZero(
                                  actualRate,
                                  benefits.commissionWeight
                                ) ? (
                                  <span className='text-grey-sub-small '>
                                    {'Incl. '}
                                    {numberWithCommas(
                                      parseFloat(
                                        (actualRate *
                                          benefits.commissionWeight) /
                                          100
                                      ).toFixed(2)
                                    )}{' '}
                                    {displayCurrency} {'commission'}{' '}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {actualRate && discountWeight > 0 && paymentType && (
                              <>
                                {isGreaterThanZero(
                                  actualRate,
                                  discountWeight
                                ) ? (
                                  <span className='text-grey-sub-small '>
                                    {paymentType !== 'rate_type_net'
                                      ? 'Incl.'
                                      : ''}{' '}
                                    {numberWithCommas(
                                      parseFloat(
                                        (actualRate * discountWeight) / 100
                                      ).toFixed(2)
                                    )}{' '}
                                    {displayCurrency}{' '}
                                    {paymentType === 'rate_type_net'
                                      ? 'discount applied'
                                      : 'commission'}{' '}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </Col>
              </div>
              {arrival && (
                <>
                  <div
                    className={`mt-2 ${departure ? '' : 'vehicle-img-value'}`}
                  >
                    <div className='card-body card-body-mobile'>
                      <h5 className='sub-title'>VIP Meet & Greet</h5>
                    </div>
                    <div className={`d-flex ${benefits ? '' : 'mb-3'}`}>
                      <Card.Title className='vehicle-title'>Arrival</Card.Title>
                      <Col className='card-action col ml-2' xs='auto'>
                        <>
                          <div className='lead'>
                            {numberWithCommas(
                              getFormattedPrice(arrival.vipRate)
                            )}
                            {` ${displayCurrency}`}{' '}
                            {!isAdmin && (
                              <>
                                {benefits &&
                                !hideCommIcon &&
                                (benefits.commissionWeight ||
                                  benefits.discountWeight) ? (
                                  <>
                                    {isGreaterThanZero(
                                      arrival.rate,
                                      benefits.commissionWeight && !hideCommIcon
                                    ) ? (
                                      <img
                                        className='info-icon'
                                        src='/images/icons/icon-information.png'
                                        alt=''
                                        onClick={() => {
                                          openCommissionModal(
                                            numberWithCommas(
                                              parseFloat(
                                                (arrival.rate *
                                                  benefits.commissionWeight) /
                                                  100
                                              ).toFixed(2)
                                            ),
                                            benefits.commissionWeight,
                                            null,
                                            null,
                                            displayCurrency
                                          )
                                        }}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      </Col>
                    </div>
                    <div className={`d-flex ${benefits ? 'mb-3' : ''}`}>
                      <Col className='card-action col ml-2' xs='auto'>
                        <>
                          {benefits && (
                            <>
                              {isGreaterThanZero(
                                arrival.rate,
                                benefits.discountWeight
                              ) ? (
                                <span className='text-grey-sub-small  text-incl-grey-2'>
                                  {'- '}
                                  {parseFloat(
                                    (arrival.rate * benefits.discountWeight) /
                                      100
                                  ).toFixed(2)}{' '}
                                  {displayCurrency} {'discount applied'}{' '}
                                </span>
                              ) : (
                                <></>
                              )}
                              {isAdmin && (
                                <>
                                  {isGreaterThanZero(
                                    arrival.rate,
                                    benefits.commissionWeight
                                  ) ? (
                                    <span className='text-grey-sub-small '>
                                      {'Incl. '}
                                      {numberWithCommas(
                                        parseFloat(
                                          (arrival.rate *
                                            benefits.commissionWeight) /
                                            100
                                        ).toFixed(2)
                                      )}{' '}
                                      {displayCurrency} {'commission'}{' '}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      </Col>
                    </div>
                  </div>
                </>
              )}
              {departure && (
                <>
                  <div className='mt-2 vehicle-img-value'>
                    <div className='card-body card-body-mobile'>
                      <h5 className='sub-title'>VIP Meet & Greet</h5>
                    </div>
                    <div className={`d-flex ${benefits ? '' : 'mb-3'}`}>
                      <Card.Title className='vehicle-title'>
                        Departure
                      </Card.Title>
                      <Col className='card-action col ml-2' xs='auto'>
                        <>
                          <div className='lead'>
                            {numberWithCommas(
                              getFormattedPrice(departure.vipRate)
                            )}
                            {` ${displayCurrency}`}{' '}
                            {!isAdmin && (
                              <>
                                {benefits &&
                                !hideCommIcon &&
                                (benefits.commissionWeight ||
                                  benefits.discountWeight) ? (
                                  <>
                                    {isGreaterThanZero(
                                      departure.rate,
                                      benefits.commissionWeight && !hideCommIcon
                                    ) ? (
                                      <img
                                        className='info-icon'
                                        src='/images/icons/icon-information.png'
                                        alt=''
                                        onClick={() => {
                                          openCommissionModal(
                                            numberWithCommas(
                                              parseFloat(
                                                (departure.rate *
                                                  benefits.commissionWeight) /
                                                  100
                                              ).toFixed(2)
                                            ),
                                            benefits.commissionWeight,
                                            null,
                                            null,
                                            displayCurrency
                                          )
                                        }}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                        </>
                      </Col>
                    </div>
                    <div className='d-flex mb-3'>
                      <Col className='card-action col ml-2' xs='auto'>
                        <>
                          {benefits && (
                            <>
                              {isGreaterThanZero(
                                departure.rate,
                                benefits.discountWeight
                              ) ? (
                                <span className='text-grey-sub-small  text-incl-grey-2'>
                                  {'- '}
                                  {parseFloat(
                                    (departure.rate * benefits.discountWeight) /
                                      100
                                  ).toFixed(2)}{' '}
                                  {displayCurrency} {'discount applied'}{' '}
                                </span>
                              ) : (
                                <></>
                              )}
                              {isAdmin && (
                                <>
                                  {isGreaterThanZero(
                                    departure.rate,
                                    benefits.commissionWeight
                                  ) ? (
                                    <span className='text-grey-sub-small '>
                                      {'Incl. '}
                                      {numberWithCommas(
                                        parseFloat(
                                          (departure.rate *
                                            benefits.commissionWeight) /
                                            100
                                        ).toFixed(2)
                                      )}{' '}
                                      {displayCurrency} {'commission'}{' '}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      </Col>
                    </div>
                  </div>
                </>
              )}
              {(arrival || departure) && (
                <div className='d-flex mt-3'>
                  <Card.Title className='vehicle-title'></Card.Title>
                  <Col className='card-action col ml-2' xs='auto'>
                    <>
                      <div className='lead'>
                        {arrival && departure
                          ? numberWithCommas(
                              getFormattedPrice(
                                price + arrival.vipRate + departure.vipRate
                              )
                            )
                          : arrival
                          ? numberWithCommas(
                              getFormattedPrice(price + arrival.vipRate)
                            )
                          : numberWithCommas(
                              getFormattedPrice(price + departure.vipRate)
                            )}
                        {` ${displayCurrency}`}{' '}
                      </div>
                    </>
                  </Col>
                </div>
              )}
            </>
          ) : (
            <>
              <div className='vehicle-img-wrap'>
                <img src={src} className='vehicle-img' alt='' />
              </div>
              {(isMobileBrowser || !isQuotePax) && (
                <div
                  className='d-flex align-items-center'
                  style={{ marginBottom: '0.8rem' }}
                >
                  {passangersCapacity && passangersCapacity !== 0 ? (
                    <VehicleValue value={passangersCapacity} icon='team' />
                  ) : (
                    <></>
                  )}

                  {lugageCapacity && lugageCapacity !== 0 ? (
                    <VehicleValue value={lugageCapacity} icon='portfolio' />
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <div className='flex-grow-1 d-flex'>
                <Col className='flex-grow-1 d-flex align-items-center px-1'>
                  <Card.Body
                    style={isQuoteModel ? { height: 'fit-content' } : {}}
                  >
                    <h5
                      className={
                        electricVehicle
                          ? 'sub-title-electric sub-title'
                          : 'sub-title'
                      }
                    >
                      {electricVehicle ? <IsElectric /> : displayCategory}
                    </h5>
                    {/* {electricVehicle && <IsElectric />} */}
                    <Card.Title
                      className={`${
                        electricVehicle
                          ? 'vehicle-title electric-vehicle-title'
                          : 'vehicle-title'
                      }
                      vehicle-title-limit
                      `}
                    >
                      {/* {electricVehicle && <IsElectric />} */}

                      {title}
                    </Card.Title>
                    <div className='d-flex align-items-center'>
                      {years && !passangersCapacity && !lugageCapacity ? (
                        <></>
                      ) : (
                        <div className='d-flex align-items-center'>
                          {passangersCapacity && passangersCapacity !== 0 ? (
                            <VehicleValue
                              value={passangersCapacity}
                              icon='team'
                            />
                          ) : (
                            <></>
                          )}
                          {lugageCapacity && lugageCapacity !== 0 ? (
                            <VehicleValue
                              value={lugageCapacity}
                              icon='portfolio'
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Col>
                <Col
                  style={
                    isQuoteCarRental ? { paddingBottom: '0px !important' } : {}
                  }
                  className={`flex-grow-1 card-action  ${
                    actualRate && discountWeight >= 0 && paymentType
                      ? 'card-action-weight'
                      : ''
                  } 
                  vehicle-price-width
                  `}
                >
                  <>
                    {priceDescription ? (
                      <div className='hint-text'>{priceDescription}</div>
                    ) : (
                      ''
                    )}
                    <div
                      className={`lead ${
                        isEditablePrice ? 'editable-vehicle-rate mb-1' : 'mb-2'
                      }`}
                    >
                      {isEditablePrice ? (
                        <>
                          <FormField
                            field={{
                              name: `name-price-${title}`,
                              value: originalPrice,
                              onChange: evt => {
                                setOriginalPrice(evt.target.value)
                                updateRatesOriginal(evt.target.value)
                              },
                            }}
                            label='original price'
                            disabled={disabled}
                          />
                          {markupWeight > 0 && (
                            <span
                              className='text-brown-sub-left'
                              style={{
                                float: 'none',
                                marginTop: '-5px',
                                marginBottom: '5px',
                              }}
                            >
                              {`${markupPrice} ${displayCurrency} markup applied`}
                            </span>
                          )}
                          <FormField
                            field={{
                              name: `name-price-${title}`,
                              value: newPrice,

                              onChange: evt => {
                                setNewPrice(evt.target.value)
                                updateRates(evt.target.value)
                              },
                            }}
                            style={{ border: '1px solid black' }}
                            disabled={disabled}
                            label='charged price'
                          />
                        </>
                      ) : (
                        <>
                          {price && numberWithCommas(getFormattedPrice(price))}
                        </>
                      )}
                      {isEditablePrice && memberBenefits ? (
                        <>
                          <span className='text-grey-sub-small  text-incl-grey-2'>
                            {'-'} {memberBenefits.discountApplied}{' '}
                            {`${displayCurrency} `}
                            {memberBenefits.type === 'fixedDiscount' ? (
                              <>
                                {memberBenefitsCurrency !== displayCurrency
                                  ? `(${numberWithCommas(
                                      memberBenefits.amount
                                    )} ${getLabelFromValue(
                                      defaultCurrencies,
                                      memberBenefits.currency
                                    )}) discount applied`
                                  : 'discount applied'}
                              </>
                            ) : (
                              ` (${numberWithCommas(
                                memberBenefits.amount
                              )} %) discount applied`
                            )}
                          </span>
                          {isEditablePrice && actualRate && benefits ? (
                            <>
                              {isGreaterThanZero(
                                actualRate,
                                benefits.commissionWeight
                              ) ? (
                                <span className='text-grey-sub-small '>
                                  {numberWithCommas(
                                    parseFloat(
                                      (actualRate * benefits.commissionWeight) /
                                        100
                                    ).toFixed(2)
                                  )}{' '}
                                  {displayCurrency} {'commission'}{' '}
                                </span>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {isEditablePrice &&
                                actualRate &&
                                discountWeight >= 0 &&
                                paymentType &&
                                paymentType !== 'rate_type_net' && (
                                  <>
                                    {isGreaterThanZero(
                                      actualRate,
                                      discountWeight
                                    ) ? (
                                      <span className='text-grey-sub-small '>
                                        {''}{' '}
                                        {numberWithCommas(
                                          parseFloat(
                                            (actualRate * discountWeight) / 100
                                          ).toFixed(2)
                                        )}{' '}
                                        {displayCurrency} {'discount applied'}{' '}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {isEditablePrice && actualRate && benefits ? (
                            <>
                              {isGreaterThanZero(
                                editedActualRate
                                  ? editedActualRate
                                  : actualRate,
                                benefits.discountWeight
                              ) ? (
                                <span
                                  className='text-brown-sub-left'
                                  style={{
                                    float: 'none',
                                    marginTop: '-5px',
                                    marginBottom: '5px',
                                  }}
                                >
                                  {'Incl.'}{' '}
                                  {numberWithCommas(
                                    parseFloat(
                                      ((editedActualRate
                                        ? editedActualRate
                                        : actualRate) *
                                        benefits.discountWeight) /
                                        100
                                    ).toFixed(2)
                                  )}{' '}
                                  {displayCurrency} {'discount applied'}{' '}
                                </span>
                              ) : (
                                <></>
                              )}
                              {isGreaterThanZero(
                                editedActualRate
                                  ? editedActualRate
                                  : actualRate,
                                benefits.commissionWeight
                              ) ? (
                                <span
                                  className='text-brown-sub-left'
                                  style={{
                                    float: 'none',
                                    marginTop: '-5px',
                                    marginBottom: '5px',
                                  }}
                                >
                                  {' '}
                                  {numberWithCommas(
                                    parseFloat(
                                      ((editedActualRate
                                        ? editedActualRate
                                        : actualRate) *
                                        benefits.commissionWeight) /
                                        100
                                    ).toFixed(2)
                                  )}{' '}
                                  {displayCurrency} {'commission'}{' '}
                                </span>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {isEditablePrice &&
                                actualRate &&
                                discountWeight &&
                                paymentType && (
                                  <>
                                    {isGreaterThanZero(
                                      editedActualRate
                                        ? editedActualRate
                                        : actualRate,
                                      discountWeight
                                    ) ? (
                                      <span
                                        className='text-brown-sub-left'
                                        style={{
                                          float: 'none',
                                          marginTop: '-5px',
                                          marginBottom: '5px',
                                        }}
                                      >
                                        {paymentType !== 'rate_type_net'
                                          ? 'Incl.'
                                          : ''}{' '}
                                        {numberWithCommas(
                                          parseFloat(
                                            ((editedActualRate
                                              ? editedActualRate
                                              : actualRate) *
                                              discountWeight) /
                                              100
                                          ).toFixed(2)
                                        )}{' '}
                                        {displayCurrency}{' '}
                                        {paymentType === 'rate_type_net'
                                          ? 'discount applied'
                                          : 'commission'}{' '}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                      {displayCurrency && ` ${displayCurrency}`}{' '}
                      {priceDescription && type === 'car-rent' && (
                        <span>
                          /<span className='font-12'>day</span>
                        </span>
                      )}
                      {!isEditablePrice && memberBenefits ? (
                        <>
                          {benefits &&
                          !hideCommIcon &&
                          (benefits.commissionWeight ||
                            benefits.discountWeight) ? (
                            <>
                              {isGreaterThanZero(
                                actualRate ? actualRate : price,
                                benefits.commissionWeight
                              ) ? (
                                <img
                                  alt=''
                                  className={
                                    priceDescription && type === 'car-rent'
                                      ? 'info-icon-rentals'
                                      : 'info-icon'
                                  }
                                  src='/images/icons/icon-information.png'
                                  onClick={() => {
                                    openCommissionModal(
                                      numberWithCommas(
                                        parseFloat(
                                          ((actualRate ? actualRate : price) *
                                            benefits.commissionWeight) /
                                            100
                                        ).toFixed(2)
                                      ),
                                      benefits.commissionWeight,
                                      null,
                                      null,
                                      displayCurrency
                                    )
                                  }}
                                ></img>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              {discountWeight &&
                                paymentType &&
                                paymentType !== 'rate_type_net' &&
                                !hideCommIcon && (
                                  <>
                                    {isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      discountWeight
                                    ) ? (
                                      <img
                                        alt=''
                                        className={
                                          priceDescription &&
                                          type === 'car-rent'
                                            ? 'info-icon-rentals'
                                            : 'info-icon'
                                        }
                                        src='/images/icons/icon-information.png'
                                        onClick={() => {
                                          openCommissionModal(
                                            numberWithCommas(
                                              parseFloat(
                                                ((actualRate
                                                  ? actualRate
                                                  : price) *
                                                  discountWeight) /
                                                  100
                                              ).toFixed(2)
                                            ),
                                            discountWeight,
                                            null,
                                            null,
                                            displayCurrency
                                          )
                                        }}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {!isEditablePrice &&
                          benefits &&
                          !hideCommIcon &&
                          (benefits.commissionWeight ||
                            benefits.discountWeight) ? (
                            <>
                              <img
                                alt=''
                                className={
                                  priceDescription && type === 'car-rent'
                                    ? 'info-icon-rentals'
                                    : 'info-icon'
                                }
                                src='/images/icons/icon-information.png'
                                onClick={() => {
                                  openCommissionModal(
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            ((actualRate ? actualRate : price) *
                                              benefits.commissionWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? benefits.commissionWeight
                                      : null,
                                    isGreaterThanZero(
                                      actualRate,
                                      benefits.discountWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            (actualRate *
                                              benefits.discountWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate,
                                      benefits.discountWeight
                                    )
                                      ? {
                                          weight: benefits.discountWeight,
                                          code: benefits.code,
                                        }
                                      : null,
                                    displayCurrency
                                  )
                                }}
                              ></img>
                            </>
                          ) : (
                            <>
                              {!isEditablePrice &&
                                discountWeight &&
                                paymentType &&
                                !hideCommIcon && (
                                  <>
                                    {isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      discountWeight
                                    ) ? (
                                      <img
                                        alt=''
                                        className={
                                          priceDescription &&
                                          type === 'car-rent'
                                            ? 'info-icon-rentals'
                                            : 'info-icon'
                                        }
                                        src='/images/icons/icon-information.png'
                                        onClick={() => {
                                          openCommissionModal(
                                            numberWithCommas(
                                              parseFloat(
                                                ((actualRate
                                                  ? actualRate
                                                  : price) *
                                                  discountWeight) /
                                                  100
                                              ).toFixed(2)
                                            ),
                                            discountWeight,
                                            null,
                                            null,
                                            displayCurrency
                                          )
                                        }}
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                      {durationType &&
                      durationType === 'hourly' &&
                      vehicleHourlyRate ? (
                        <span className='text-grey-sub-small text-incl-grey-2'>
                          {`Extra hour: ${numberWithCommas(
                            parseFloat(chargedVRate).toFixed(2)
                          )} ${displayCurrency}`}
                        </span>
                      ) : (
                        <></>
                      )}
                      {!isEditablePrice &&
                      memberBenefits &&
                      !priceDescription ? (
                        <>
                          <span className='text-grey-sub-small  text-incl-grey-2'>
                            {'-'} {memberBenefits.discountApplied}{' '}
                            {`${displayCurrency} `}
                            {memberBenefits.type === 'fixedDiscount' ? (
                              <>
                                {memberBenefitsCurrency !== displayCurrency
                                  ? `(${numberWithCommas(
                                      memberBenefits.amount
                                    )} ${getLabelFromValue(
                                      defaultCurrencies,
                                      memberBenefits.currency
                                    )}) discount applied`
                                  : 'discount applied'}
                              </>
                            ) : (
                              ` (${numberWithCommas(
                                memberBenefits.amount
                              )} %) discount applied`
                            )}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    {searchValues &&
                      searchValues.mode &&
                      renderMinimumHours(minHours, searchValues)}
                    {!isQuoteCarRental && [
                      !!selected && !checkbox ? (
                        <img
                          className='vehicle-selected'
                          src='/images/icons/success_icon.png'
                          alt=''
                        />
                      ) : isAdmin && buttonTitle === 'View booking' ? (
                        <Button
                          className='select-btn select-vehicle'
                          variant='brown'
                          onClick={handler}
                        >
                          {buttonTitle}
                        </Button>
                      ) : isAdmin && buttonTitle === 'Book now' ? (
                        <></>
                      ) : buttonTitle === 'Same' ? (
                        <></>
                      ) : (
                        <>
                          {buttonTitle && (
                            <SmallUp>
                              <Button
                                className='select-btn select-vehicle'
                                variant='brown'
                                onClick={handler}
                              >
                                {buttonTitle}
                              </Button>
                            </SmallUp>
                          )}
                        </>
                      ),
                    ]}
                  </>
                </Col>
              </div>
              {isQuoteModel && !isQuoteCarRental && (
                <>
                  <Row className='ml-3' style={{ flexBasis: '100%' }}>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: `vehicle-hourly-rate`,
                          value: vRate,
                          onChange: e => {
                            setVRate(e.target.value)
                            updateChargedVRate(e.target.value)
                          },
                        }}
                        label='original vehicle hourly rate'
                      />
                      <FormField
                        field={{
                          name: `vehicle-hourly-rate-after-discount`,
                          value: chargedVRate,
                          onChange: e => {
                            setChargedVRate(e.target.value)
                            updateVRate(e.target.value)
                          },
                        }}
                        style={{ border: '1px solid black' }}
                        label='charged vehicle hourly rate'
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: `name-cancellation-policy-chauffeur-service`,
                          value: cancelPolicy,
                          onChange: evt => {
                            setCancelPolicy(evt.target.value)
                            onChangePolicy(evt.target.value)
                          },
                        }}
                        label='cancellation policy'
                      />
                    </Col>
                    <Col>
                      <Row style={{ justifyContent: 'flex-end' }}>
                        {globalRateId &&
                          globalRateLocationId &&
                          !isQuoteCarRental && (
                            <Link
                              to={`/rates-global/edit/${globalRateId}/location/${globalRateLocationId}`}
                              target='_blank'
                              className={
                                showCheckbox
                                  ? 'text-brown pointer-link'
                                  : 'text-brown pointer-link mb-2'
                              }
                              style={{
                                fontSize: '12px',
                                marginTop: '48%',
                              }}
                            >
                              Check pricing
                            </Link>
                          )}
                      </Row>
                      <Row style={{ justifyContent: 'flex-end' }}>
                        {!isQuoteCarRental && isAdmin && (
                          <FormCheckbox
                            checked={checked}
                            name={title}
                            globalRateId={globalRateId}
                            handleChange={() => onCheck(cancelPolicy)}
                            label=''
                            className='vehicle-checkbox is-editable-checkbox'
                          />
                        )}
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              {searchValues &&
                searchValues.mode !== 'self_drive' &&
                isViewQuote && (
                  <Row className='ml-3' style={{ flexBasis: '100%' }}>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: `vehicle-hourly-rate`,
                          value: vRate,
                        }}
                        label='original vehicle hourly rate'
                        disabled={disabled}
                      />
                      <FormField
                        field={{
                          name: `vehicle-hourly-rate-after-discount`,
                          value: chargedVRate,
                        }}
                        style={{ border: '1px solid black' }}
                        label='charged vehicle hourly rate'
                        disabled={disabled}
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: `name-cancellation-policy-chauffeur-service`,
                          value: cancelPolicy,
                        }}
                        label='cancellation policy'
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                )}
              {isQuoteCarRental && (
                <>
                  <hr />
                  <Row className={'ml-3'} style={{ flexBasis: '100%' }}>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: 'dailyMileage',
                          value: changedRates.dailyMileage,
                          onChange: evt => {
                            evt.persist()
                            setChangedRates(obj => ({
                              ...obj,
                              dailyMileage: evt.target.value,
                            }))
                            onDepositChange({
                              dailyMileage: evt.target.value,
                              deposit: changedRates.deposit,
                              extraMile: changedRates.extraMile,
                            })
                          },
                        }}
                        label={`${
                          continent && continent.unitSystem === 'KM'
                            ? 'KMs included per day'
                            : 'MILES included per day'
                        }`}
                        disabled={disabled}
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: 'extraMile',
                          value: changedRates.extraMile,
                          onChange: evt => {
                            evt.persist()
                            setChangedRates(obj => ({
                              ...obj,
                              extraMile: evt.target.value,
                            }))
                            onDepositChange({
                              dailyMileage: changedRates.dailyMileage,
                              deposit: changedRates.deposit,
                              extraMile: evt.target.value,
                            })
                          },
                        }}
                        label={`${
                          continent && continent.unitSystem === 'KM'
                            ? 'Price per extra km'
                            : 'Price per extra mile'
                        }`}
                        disabled={disabled}
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        field={{
                          name: 'deposit',
                          value: changedRates.deposit,
                          onChange: evt => {
                            evt.persist()
                            setChangedRates(obj => ({
                              ...obj,
                              deposit: evt.target.value,
                            }))
                            onDepositChange({
                              dailyMileage: changedRates.dailyMileage,
                              deposit: evt.target.value,
                              extraMile: changedRates.extraMile,
                            })
                          },
                        }}
                        label={'Deposit'}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                  {isQuoteModel && (
                    <Col md={4} className='ml-3'>
                      <FormField
                        field={{
                          name: `name-cancellation-policy-carRental`,
                          value: cancelPolicy,
                          onChange: evt => {
                            setCancelPolicy(evt.target.value)
                            onChangePolicy(evt.target.value)
                          },
                        }}
                        label='cancellation policy'
                      />
                    </Col>
                  )}
                  {isViewQuote && (
                    <Col md={4} className='ml-3'>
                      <FormField
                        field={{
                          name: `name-cancellation-policy-carRental`,
                          value: cancelPolicy,
                        }}
                        label='cancellation policy'
                        disabled={disabled}
                      />
                    </Col>
                  )}
                  <Col>
                    <Row style={{ justifyContent: 'flex-end' }}>
                      {globalRateId && globalRateLocationId && (
                        <Link
                          to={`/rates-global/edit/${globalRateId}/location/${globalRateLocationId}`}
                          target='_blank'
                          className={
                            showCheckbox
                              ? 'text-brown pointer-link'
                              : 'text-brown pointer-link mb-2'
                          }
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          Check pricing
                        </Link>
                      )}
                    </Row>
                    <Row style={{ justifyContent: 'flex-end' }}>
                      {checkbox ? (
                        <>
                          {showCheckbox && (
                            <FormCheckbox
                              checked={checked}
                              name={title}
                              globalRateId={globalRateId}
                              handleChange={() => onCheck(cancelPolicy)}
                              label=''
                              className='vehicle-checkbox is-editable-checkbox'
                            />
                          )}
                        </>
                      ) : !!selected && !checkbox ? (
                        <img
                          className='vehicle-selected'
                          src='/images/icons/success_icon.png'
                          alt=''
                        />
                      ) : isAdmin && buttonTitle === 'View booking' ? (
                        <Button
                          className='select-btn select-vehicle'
                          variant='brown'
                          onClick={handler}
                        >
                          {buttonTitle}
                        </Button>
                      ) : isAdmin && buttonTitle === 'Book now' ? (
                        <></>
                      ) : buttonTitle === 'Same' ? (
                        <></>
                      ) : (
                        <>
                          <SmallUp>
                            <Button
                              className='select-btn select-vehicle'
                              variant='brown'
                              onClick={handler}
                            >
                              {buttonTitle}
                            </Button>
                          </SmallUp>
                        </>
                      )}
                    </Row>
                  </Col>
                </>
              )}
            </>
          )}
          {isDropDown && (
            <SmallUp>
              <div className='booking-card-footer'>
                <span
                  className={`expandable ${expanded ? 'expanded' : ''}`}
                  onClick={() => {
                    setExpanded(!expanded)
                    if (!isExpanded) {
                      dispatch({ type: OPEN_CAR_DROP_DOWN, payload: id })
                    } else {
                      dispatch({ type: CLOSE_CAR_DROP_DOWN, payload: id })
                    }
                  }}
                />
              </div>
            </SmallUp>
          )}
          {isDetailsDropDown && (
            <SmallUp>
              <div className='booking-card-footer'>
                <span
                  className={`expandable ${detailsExpanded ? 'expanded' : ''}`}
                  onClick={() => {
                    setDetailsExpanded(!detailsExpanded)
                  }}
                />
              </div>
            </SmallUp>
          )}
        </Card>
        {isDropDown && expanded && (
          <>
            {type !== 'car-rent' ? (
              <Card className='vehicle-detail-card '>
                <span
                  className='close-icon'
                  onClick={() => {
                    dispatch({ type: CLOSE_CAR_DROP_DOWN, payload: id })
                    setExpanded(!expanded)
                  }}
                >
                  <img src='/images/icons/close-2.png' alt='' />
                </span>
                <div className='card-body'>
                  <div className='row'>
                    <Col md='4'>
                      <label className='expand-title'>SERVICE DETAILS</label>
                      <p>Meet & Greet</p>
                      <p>Experienced chauffeur</p>
                      <p>Top-of-the-range model</p>
                      <p>On-board amenities</p>
                    </Col>
                    {searchValues && !searchValues.durationType && (
                      <Col md='4' style={{ paddingLeft: '44px' }}>
                        <label className='expand-title'>FREE WAIT TIME</label>
                        <p>
                          {isRolzoFirstCompany
                            ? isAirport
                              ? 90
                              : isPort
                              ? 60
                              : 30
                            : isAirport
                            ? 60
                            : isPort
                            ? 30
                            : 15}{' '}
                          minutes
                        </p>
                      </Col>
                    )}
                    <Col md='4'>
                      <label className='expand-title'>FREE CANCELLATION</label>
                      {/* cancellation policy for Transfer */}
                      {!searchValues.durationType ? (
                        <>
                          {showWarningBanner &&
                          cancellationPolicy.transferPolicy ? (
                            <p>{`${cancellationPolicy.transferPolicy} before pick-up`}</p>
                          ) : (
                            <>
                              {isMoreThanTwoHours ? (
                                <p>24 hours before pick-up</p>
                              ) : thereIsGlobalPolicy ? (
                                <p>
                                  {getGlobalCancellationPolicy({
                                    bookingType: 'airport_transfer',
                                    durationType: null,
                                    pickUpLocation: searchValues.pickUpLocation,
                                    globalPolicy: globalCancellationPolicy,
                                    vehicleCategory: category,
                                  })}
                                </p>
                              ) : (
                                <>
                                  {/* Policies for certain vehicles */}
                                  {vehicleTypesPolicy.includes(category) ? (
                                    <p>48 hours before pick-up</p>
                                  ) : (
                                    <>
                                      {category === 'van' ||
                                      searchValues.pickUpLocation.country ===
                                        'JP' ? (
                                        <p>24 hours before pick-up</p>
                                      ) : (
                                        <p>4 hours before pick-up</p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* cancellation policy for Hourly & Daily */}
                          {showWarningBanner &&
                          cancellationPolicy.hourlyPolicy ? (
                            <p>{`${cancellationPolicy.hourlyPolicy} before pick-up`}</p>
                          ) : (
                            <>
                              {searchValues.durationType &&
                              searchValues.durationType.value === 'hourly' ? (
                                <>
                                  {thereIsGlobalPolicy ? (
                                    <p>
                                      {getGlobalCancellationPolicy({
                                        bookingType: 'chauffeur_services',
                                        durationType:
                                          searchValues.durationType.value,
                                        pickUpLocation:
                                          searchValues.pickUpLocation,
                                        globalPolicy: globalCancellationPolicy,
                                        vehicleCategory: category,
                                      })}
                                    </p>
                                  ) : (
                                    <>
                                      {vehicleTypesPolicy.includes(category) ? (
                                        <p>48 hours before pick-up</p>
                                      ) : (
                                        <>
                                          {category === 'van' ? (
                                            <p>24 hours before pick-up</p>
                                          ) : (
                                            <p>12 hours before pick-up</p>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {thereIsGlobalPolicy ? (
                                    <p>
                                      {getGlobalCancellationPolicy({
                                        bookingType: 'chauffeur_services',
                                        durationType:
                                          searchValues.durationType.value,
                                        pickUpLocation:
                                          searchValues.pickUpLocation,
                                        globalPolicy: globalCancellationPolicy,
                                        vehicleCategory: category,
                                      })}
                                    </p>
                                  ) : (
                                    <>
                                      {vehicleTypesPolicy.includes(category) ? (
                                        <p>48 hours before pick-up</p>
                                      ) : (
                                        <p>24 hours before pick-up</p>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </div>
                </div>
              </Card>
            ) : (
              <Card className='vehicle-detail-card car-rental-card'>
                <span
                  className='close-icon'
                  onClick={() => {
                    setExpanded(!expanded)
                  }}
                >
                  <img src='/images/icons/close-2.png' alt='' />
                </span>
                <div className='card-body'>
                  <div className='row'>
                    <Col md='4'>
                      <label className='expand-title'>
                        {`${
                          continent && continent.unitSystem !== 'KM'
                            ? 'MILEAGE'
                            : 'KILOMETRES'
                        }`}{' '}
                      </label>
                      {dailyMileageUnits !== 0 ? (
                        continent && continent.unitSystem !== 'KM' ? (
                          <p className='mb-3'>
                            {dailyMileageUnits} miles per day included
                          </p>
                        ) : (
                          <p className='mb-3'>
                            {/* {Math.floor(dailyMileageUnits * 1.60934)} km per day */}
                            {Math.round(dailyMileageUnits / 0.62137)} km per day
                            included
                          </p>
                        )
                      ) : continent && continent.unitSystem !== 'KM' ? (
                        <p>Unlimited miles</p>
                      ) : (
                        <p>Unlimited km</p>
                      )}
                      {extraMilePrice > 0 &&
                        (continent && continent.unitSystem !== 'KM' ? (
                          <>
                            <p>
                              {extraMilePrice.toFixed(2)} {displayCurrency} for
                              each{' '}
                            </p>
                            <p>additional mile</p>
                          </>
                        ) : (
                          <>
                            <p>
                              {/* {Math.ceil((extraMilePrice / 1.60934) * 100) / 100}{' '} */}
                              {/* {(
                                Math.ceil(extraMilePrice / 1.60934 / 5) * 5
                              ).toFixed(2)}{' '} */}
                              {/* {(extraMilePrice / 1.60934).toFixed(2)}{' '} */}
                              {(
                                Math.ceil((extraMilePrice / 1.60934) * 2) / 2
                              ).toFixed(2)}{' '}
                              {displayCurrency} for each{' '}
                            </p>
                            <p>additional km</p>
                          </>
                        ))}
                    </Col>
                    <Col md='4'>
                      <label className='expand-title'>SECURITY DEPOSIT</label>
                      <p className='mb-3'>
                        {numberWithCommas(getFormattedPrice(depositPrice))}{' '}
                        {depositDefaultCurrency}
                      </p>
                      <p>
                        The security deposit will be refunded when the vehicle
                        is returned without incidents
                      </p>
                    </Col>
                    <Col md='4'>
                      <label className='expand-title'>FREE CANCELLATION</label>

                      {/* cancellation policy for car rentals */}
                      {showWarningBanner &&
                      cancellationPolicy.carRentalPolicy ? (
                        <p>{`${cancellationPolicy.carRentalPolicy} before pick-up`}</p>
                      ) : (
                        <>
                          {thereIsGlobalPolicy ? (
                            <p>
                              {getGlobalCancellationPolicy({
                                bookingType: 'car_rentals',
                                durationType: null,
                                pickUpLocation: searchValues.pickUpLocation,
                                globalPolicy: globalCancellationPolicy,
                                vehicleCategory: category,
                              })}
                            </p>
                          ) : (
                            <p>48 hours before pick-up</p>
                          )}
                        </>
                      )}
                    </Col>
                  </div>
                </div>
              </Card>
            )}
          </>
        )}
      </SmallUp>
      <SmallDown>
        <Card
          className={
            isReturnVehicle
              ? active === 'inactive'
                ? `vehicle-card ${className} ${active} vehicle-card-selected`
                : `vehicle-card ${className} ${active} ${className ===
                    'chat-vehicle-card' &&
                    !status &&
                    'mb-0 pb-0'}`
              : // : className === 'chat-vehicle-card' && !status
              className === 'chat-vehicle-card' &&
                (!status || status === 'quote_in_progress')
              ? `vehicle-card chat-vehicle-card vehicle-card-noclick`
              : `vehicle-card ${className} ${active}`
          }
          onClick={() =>
            className !== 'chat-vehicle-card' && !status
              ? selectVehicle()
              : className === 'chat-vehicle-card' &&
                status &&
                status !== 'quote_in_progress'
              ? selectVehicle()
              : {}
          }
        >
          <div className='d-flex'>
            <div className='vehicle-img-wrap'>
              <img src={src} className='vehicle-img' alt='' />
            </div>
            {(isMobileBrowser || !isQuotePax) && (
              <div
                className='d-flex align-items-center'
                style={{ marginBottom: '0.8rem' }}
              >
                {passangersCapacity && passangersCapacity !== 0 ? (
                  <VehicleValue value={passangersCapacity} icon='team' />
                ) : (
                  <></>
                )}

                {lugageCapacity && lugageCapacity !== 0 ? (
                  <VehicleValue value={lugageCapacity} icon='portfolio' />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          <div className='card-body card-body-mobile'>
            <h5
              className={
                electricVehicle ? 'sub-title-electric sub-title' : 'sub-title'
              }
            >
              {electricVehicle ? <IsElectric /> : displayCategory}
            </h5>

            {priceDescription ? (
              <div className='hint-text'>{priceDescription}</div>
            ) : (
              ''
            )}
          </div>
          <div
            className={isMobileBrowser && isQuoteSearchAdmin ? '' : 'd-flex'}
          >
            <Card.Title
              className={
                electricVehicle
                  ? 'vehicle-title electric-vehicle-title'
                  : 'vehicle-title'
              }
            >
              {title}
            </Card.Title>
            <Col className='card-action col ml-2' xs='auto'>
              <>
                <div
                  className={`lead ${
                    isEditablePrice ? 'editable-vehicle-rate' : ''
                  }`}
                >
                  {isEditablePrice ? (
                    <>
                      <FormField
                        field={{
                          name: `name-price-${title}`,
                          value: originalPrice,
                          onChange: evt => {
                            setOriginalPrice(evt.target.value)
                            updateRatesOriginal(evt.target.value)
                          },
                        }}
                        label='original price'
                      />
                      <FormField
                        field={{
                          name: `name-price-${title}`,
                          value: newPrice,
                          onChange: evt => {
                            setNewPrice(evt.target.value)
                            // onPriceChange(evt.target.value, 'b') // remove this
                            updateRates(evt.target.value)
                          },
                        }}
                      />
                      {isQuoteModel && (
                        <FormField
                          field={{
                            name: `name-cancellation-policy`,
                            value: cancelPolicy,
                            onChange: evt => {
                              setCancelPolicy(evt.target.value)
                              onChangePolicy(evt.target.value)
                            },
                          }}
                          label='cancellation policy'
                        />
                      )}
                    </>
                  ) : (
                    numberWithCommas(getFormattedPrice(price))
                  )}
                  {isQuoteCarRental && isMobileBrowser && (
                    <Col className={'ml-3'} style={{ flexBasis: '100%' }}>
                      <Row md={4}>
                        <FormField
                          field={{
                            name: 'dailyMileage',
                            value: changedRates.dailyMileage,
                            onChange: evt => {
                              evt.persist()
                              setChangedRates(obj => ({
                                ...obj,
                                dailyMileage: evt.target.value,
                              }))
                              onDepositChange({
                                dailyMileage: evt.target.value,
                                deposit: changedRates.deposit,
                                extraMile: changedRates.extraMile,
                              })
                            },
                          }}
                          label={`${
                            continent && continent.unitSystem === 'KM'
                              ? 'KM included'
                              : 'MILES included'
                          }`}
                        />
                      </Row>
                      <Row md={4}>
                        <FormField
                          field={{
                            name: 'extraMile',
                            value: changedRates.extraMile,
                            onChange: evt => {
                              evt.persist()
                              setChangedRates(obj => ({
                                ...obj,
                                extraMile: evt.target.value,
                              }))
                              onDepositChange({
                                dailyMileage: changedRates.dailyMileage,
                                deposit: changedRates.deposit,
                                extraMile: evt.target.value,
                              })
                            },
                          }}
                          label={`${
                            continent && continent.unitSystem === 'KM'
                              ? 'Price per extra km'
                              : 'Price per extra mile'
                          }`}
                        />
                      </Row>
                      <Row md={4}>
                        <FormField
                          field={{
                            name: 'deposit',
                            value: changedRates.deposit,
                            onChange: evt => {
                              evt.persist()
                              setChangedRates(obj => ({
                                ...obj,
                                deposit: evt.target.value,
                              }))
                              onDepositChange({
                                dailyMileage: changedRates.dailyMileage,
                                deposit: evt.target.value,
                                extraMile: changedRates.extraMile,
                              })
                            },
                          }}
                          label={'Deposit'}
                        />
                      </Row>
                    </Col>
                  )}
                  {` ${displayCurrency}`}
                  {priceDescription && type === 'car-rent' && (
                    <span>
                      /<span className='font-12'>day</span>
                    </span>
                  )}
                  {!isAdmin && (
                    <>
                      {memberBenefits ? (
                        <>
                          {benefits &&
                          !hideCommIcon &&
                          (benefits.commissionWeight ||
                            benefits.discountWeight) ? (
                            <>
                              <img
                                className={
                                  priceDescription && type === 'car-rent'
                                    ? 'info-icon-rentals'
                                    : 'info-icon'
                                }
                                src='/images/icons/icon-information.png'
                                alt=''
                                onClick={() => {
                                  openCommissionModal(
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            ((actualRate ? actualRate : price) *
                                              benefits.commissionWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? benefits.commissionWeight
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.discountWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            (actualRate *
                                              benefits.discountWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.discountWeight
                                    )
                                      ? {
                                          weight: benefits.discountWeight,
                                          code: benefits.code,
                                        }
                                      : null,
                                    displayCurrency
                                  )
                                }}
                              ></img>
                            </>
                          ) : (
                            <>
                              {discountWeight >= 0 &&
                                paymentType &&
                                paymentType !== 'rate_type_net' &&
                                !hideCommIcon && (
                                  <>
                                    {isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      discountWeight
                                    ) ? (
                                      <img
                                        className={
                                          priceDescription &&
                                          type === 'car-rent'
                                            ? 'info-icon-rentals'
                                            : 'info-icon'
                                        }
                                        src='/images/icons/icon-information.png'
                                        onClick={() => {
                                          openCommissionModal(
                                            null,
                                            null,
                                            isGreaterThanZero(
                                              actualRate ? actualRate : price,
                                              discountWeight
                                            )
                                              ? numberWithCommas(
                                                  parseFloat(
                                                    (actualRate *
                                                      benefits.discountWeight) /
                                                      100
                                                  ).toFixed(2)
                                                )
                                              : null,
                                            isGreaterThanZero(
                                              actualRate ? actualRate : price,
                                              discountWeight
                                            )
                                              ? {
                                                  weight:
                                                    benefits.discountWeight,
                                                  code: benefits.code,
                                                }
                                              : null,
                                            displayCurrency
                                          )
                                        }}
                                        alt=''
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {benefits &&
                          !hideCommIcon &&
                          (benefits.commissionWeight ||
                            benefits.discountWeight) ? (
                            <>
                              <img
                                className={
                                  priceDescription && type === 'car-rent'
                                    ? 'info-icon-rentals'
                                    : 'info-icon'
                                }
                                src='/images/icons/icon-information.png'
                                onClick={() => {
                                  openCommissionModal(
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            ((actualRate ? actualRate : price) *
                                              benefits.commissionWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.commissionWeight
                                    )
                                      ? benefits.commissionWeight
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.discountWeight
                                    )
                                      ? numberWithCommas(
                                          parseFloat(
                                            (actualRate *
                                              benefits.discountWeight) /
                                              100
                                          ).toFixed(2)
                                        )
                                      : null,
                                    isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      benefits.discountWeight
                                    )
                                      ? {
                                          weight: benefits.discountWeight,
                                          code: benefits.code,
                                        }
                                      : null,
                                    displayCurrency
                                  )
                                }}
                                alt=''
                              />
                            </>
                          ) : (
                            <>
                              {discountWeight >= 0 &&
                                paymentType &&
                                !hideCommIcon && (
                                  <>
                                    {isGreaterThanZero(
                                      actualRate ? actualRate : price,
                                      discountWeight
                                    ) ? (
                                      <img
                                        className={
                                          priceDescription &&
                                          type === 'car-rent'
                                            ? 'info-icon-rentals'
                                            : 'info-icon'
                                        }
                                        src='/images/icons/icon-information.png'
                                        onClick={() => {
                                          openCommissionModal(
                                            null,
                                            null,
                                            numberWithCommas(
                                              parseFloat(
                                                ((actualRate
                                                  ? actualRate
                                                  : price) *
                                                  discountWeight) /
                                                  100
                                              ).toFixed(2)
                                            ),
                                            discountWeight,
                                            displayCurrency
                                          )
                                        }}
                                        alt=''
                                      ></img>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  {isMobileBrowser &&
                    isQuoteSearchAdmin &&
                    globalRateId &&
                    globalRateLocationId && (
                      <Link
                        to={`/rates-global/edit/${globalRateId}/location/${globalRateLocationId}`}
                        target='_blank'
                        className={
                          showCheckbox
                            ? 'text-brown pointer-link'
                            : 'text-brown pointer-link mb-2'
                        }
                      >
                        Check pricing
                      </Link>
                    )}
                  {isMobileBrowser && isQuoteSearchAdmin && checkbox && (
                    <>
                      {showCheckbox && (
                        <FormCheckbox
                          checked={checked}
                          name={title}
                          globalRateId={globalRateId}
                          handleChange={() => onCheck(cancelPolicy)}
                          label=''
                          className='vehicle-checkbox is-editable-checkbox-admin mb-3'
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            </Col>
          </div>
          <div className='d-flex'>
            {/* <div className='d-flex align-items-center'>
              {passangersCapacity && passangersCapacity !== 0 ? (
                <VehicleValue value={passangersCapacity} icon='team' />
              ) : (
                <></>
              )}
              {lugageCapacity && lugageCapacity !== 0 ? (
                <VehicleValue value={lugageCapacity} icon='portfolio' />
              ) : (
                <></>
              )}
            </div> */}
            <Col className='card-action col ml-2' xs='auto'>
              {durationType &&
              durationType === 'hourly' &&
              vehicleHourlyRate ? (
                <span className='text-grey-sub-small text-incl-grey-2'>
                  {`Extra hour: ${numberWithCommas(
                    parseFloat(chargedVRate).toFixed(2)
                  )} ${displayCurrency}`}
                </span>
              ) : (
                <></>
              )}
              {memberBenefits ? (
                <>
                  <span className='text-grey-sub-small  text-incl-grey-2'>
                    {'-'} {memberBenefits.discountApplied}{' '}
                    {`${displayCurrency} `}
                    {memberBenefits.type === 'fixedDiscount' ? (
                      <>
                        {memberBenefitsCurrency !== displayCurrency
                          ? `(${numberWithCommas(
                              memberBenefits.amount
                            )} ${getLabelFromValue(
                              defaultCurrencies,
                              memberBenefits.currency
                            )}) discount applied`
                          : 'discount applied'}
                      </>
                    ) : (
                      ` (${numberWithCommas(
                        memberBenefits.amount
                      )} %) discount applied`
                    )}
                  </span>
                  {isAdmin && (
                    <>
                      {actualRate && benefits ? (
                        <>
                          {className === 'chat-vehicle-card' &&
                          isGreaterThanZero(
                            actualRate,
                            benefits.commissionWeight
                          ) ? (
                            <span className='text-grey-sub-small '>
                              {numberWithCommas(
                                parseFloat(
                                  (actualRate * benefits.commissionWeight) / 100
                                ).toFixed(2)
                              )}{' '}
                              {displayCurrency}
                              {' commission '}
                            </span>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <>
                          {className === 'chat-vehicle-card' &&
                            actualRate &&
                            discountWeight >= 0 &&
                            paymentType &&
                            paymentType !== 'rate_type_net' && (
                              <>
                                {isGreaterThanZero(
                                  actualRate,
                                  discountWeight
                                ) ? (
                                  <span className='text-grey-sub-small '>
                                    {''}{' '}
                                    {numberWithCommas(
                                      parseFloat(
                                        (actualRate * discountWeight) / 100
                                      ).toFixed(2)
                                    )}{' '}
                                    {displayCurrency} {'commission'}{' '}
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* {actualRate && benefits ? (
                    <>
                      {isGreaterThanZero(
                        actualRate,
                        benefits.discountWeight
                      ) ? (
                        <span className='text-grey-sub-small '>
                          {'-'}
                          {numberWithCommas(
                            parseFloat(
                              (actualRate * benefits.discountWeight) / 100
                            ).toFixed(2)
                          )}{' '}
                          {displayCurrency}
                          {' discount applied'}
                        </span>
                      ) : (
                        <></>
                      )}
                      {isAdmin &&
                      className === 'chat-vehicle-card' &&
                      isGreaterThanZero(
                        actualRate,
                        benefits.commissionWeight
                      ) ? (
                        <span className='text-grey-sub-small '>
                          {'Incl. '}
                          {numberWithCommas(
                            parseFloat(
                              (actualRate * benefits.commissionWeight) / 100
                            ).toFixed(2)
                          )}{' '}
                          {displayCurrency}
                          {' commission '}
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {actualRate &&
                        discountWeight >= 0 &&
                        paymentType &&
                        paymentType === 'rate_type_net' && (
                          <>
                            {className === 'chat-vehicle-card' &&
                            isGreaterThanZero(actualRate, discountWeight) ? (
                              <span className='text-grey-sub-small '>
                                {paymentType !== 'rate_type_net' ? 'Incl.' : ''}{' '}
                                {numberWithCommas(
                                  parseFloat(
                                    (actualRate * discountWeight) / 100
                                  ).toFixed(2)
                                )}{' '}
                                {displayCurrency} {'discount applied'}
                              </span>
                            ) : (
                              <></>
                            )}
                          </>
                        )}
                    </>
                  )} */}
                </>
              )}
            </Col>
          </div>
          {arrival && (
            <>
              <div className={`mt-2 ${departure ? '' : 'vehicle-img-value'}`}>
                <div className='card-body card-body-mobile'>
                  <h5 className='sub-title'>VIP Meet & Greet</h5>
                </div>
                <div className={`d-flex ${benefits ? '' : 'mb-3'}`}>
                  <Card.Title className='vehicle-title'>Arrival</Card.Title>
                  <Col className='card-action col ml-2' xs='auto'>
                    <>
                      <div className='lead'>
                        {numberWithCommas(getFormattedPrice(arrival.vipRate))}
                        {` ${displayCurrency}`}{' '}
                      </div>
                    </>
                  </Col>
                </div>
                <div className={`d-flex ${benefits ? 'mb-3' : ''}`}>
                  <Col className='card-action col ml-2' xs='auto'>
                    {arrival.rate && benefits ? (
                      <>
                        {isGreaterThanZero(
                          arrival.rate,
                          benefits.discountWeight
                        ) ? (
                          <span className='text-grey-sub-small '>
                            {'-'}
                            {numberWithCommas(
                              parseFloat(
                                (arrival.rate * benefits.discountWeight) / 100
                              ).toFixed(2)
                            )}{' '}
                            {displayCurrency}
                            {' discount applied'}
                          </span>
                        ) : (
                          <></>
                        )}
                        {isGreaterThanZero(
                          arrival.rate,
                          benefits.commissionWeight
                        ) ? (
                          <span className='text-grey-sub-small '>
                            {'Incl. '}
                            {numberWithCommas(
                              parseFloat(
                                (arrival.rate * benefits.commissionWeight) / 100
                              ).toFixed(2)
                            )}{' '}
                            {displayCurrency}
                            {' commission '}
                          </span>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </div>
              </div>
            </>
          )}
          {departure && (
            <>
              <div className='mt-2 vehicle-img-value'>
                <div className='card-body card-body-mobile'>
                  <h5 className='sub-title'>VIP Meet & Greet</h5>
                </div>
                <div className={`d-flex ${benefits ? '' : 'mb-3'}`}>
                  <Card.Title className='vehicle-title'>Departure</Card.Title>
                  <Col className='card-action col ml-2' xs='auto'>
                    <>
                      <div className='lead'>
                        {numberWithCommas(getFormattedPrice(departure.vipRate))}
                        {` ${displayCurrency}`}{' '}
                      </div>
                    </>
                  </Col>
                </div>
                <div className='d-flex mb-3'>
                  <Col className='card-action col ml-2' xs='auto'>
                    {departure.rate && benefits ? (
                      <>
                        {isGreaterThanZero(
                          departure.rate,
                          benefits.discountWeight
                        ) ? (
                          <span className='text-grey-sub-small '>
                            {'-'}
                            {numberWithCommas(
                              parseFloat(
                                (departure.rate * benefits.discountWeight) / 100
                              ).toFixed(2)
                            )}{' '}
                            {displayCurrency}
                            {' discount applied'}
                          </span>
                        ) : (
                          <></>
                        )}
                        {isGreaterThanZero(
                          departure.rate,
                          benefits.commissionWeight
                        ) ? (
                          <span className='text-grey-sub-small '>
                            {'Incl. '}
                            {numberWithCommas(
                              parseFloat(
                                (departure.rate * benefits.commissionWeight) /
                                  100
                              ).toFixed(2)
                            )}{' '}
                            {displayCurrency}
                            {' commission '}
                          </span>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </div>
              </div>
            </>
          )}
          {(arrival || departure) && (
            <div className='d-flex mt-3'>
              <Card.Title className='vehicle-title'></Card.Title>
              <Col className='card-action col ml-2' xs='auto'>
                <>
                  <div className='lead'>
                    {arrival && departure
                      ? numberWithCommas(
                          getFormattedPrice(
                            price + arrival.vipRate + departure.vipRate
                          )
                        )
                      : arrival
                      ? numberWithCommas(
                          getFormattedPrice(price + arrival.vipRate)
                        )
                      : numberWithCommas(
                          getFormattedPrice(price + departure.vipRate)
                        )}
                    {` ${displayCurrency}`}{' '}
                  </div>
                </>
              </Col>
            </div>
          )}
        </Card>
      </SmallDown>
    </>
  )
}

export default withRouter(VehicleCard)
