import React, { useState } from 'react'
import { Mention, MentionsInput } from 'react-mentions'
import MentionedDefaultStyle from './MentionedDefaultStyle'
import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'
import { GrammarlyConfigs } from '../constants'

export default function MentionsTextarea({
  value,
  onChange,
  data,
  trigger,
  formatData,
  formatMention,
  onKeyPress,
  minHeight,
  placeholder,
  onFocus,
  onBlur,
  isClient = false,
}) {
  const defaultFormat = (id, display) => {
    return `@${display}`
  }
  const [textMinHeight, setTextMinHeight] = useState(minHeight ? minHeight : 40)
  const onChageFunction = (e, newValue, newPlainTextValue, mentions) => {
    const textArea = e.target

    if (textArea && textArea.style) {
      textArea.style.height = 'auto' // Reset height to auto to get the actual scrollHeight
      const offsetHeight = textArea.scrollHeight
      setTextMinHeight(Math.max(offsetHeight, minHeight || 40))
    }

    if (onChange) {
      onChange(e, newValue, newPlainTextValue, mentions)
    }
  }
  return (
    // <GrammarlyEditorPlugin
    //   clientId={`${process.env.REACT_APP_GRAMMARLY_CLIENT_ID}`}
    //   config={GrammarlyConfigs}
    // >
    <MentionsInput
      value={value}
      onKeyPress={onKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder={!!placeholder ? placeholder : 'Reply...'}
      onChange={(e, newValue, newPlainTextValue, mentions) =>
        onChageFunction(e, newValue, newPlainTextValue, mentions)
      }
      style={MentionedDefaultStyle(textMinHeight, isClient)}
    >
      <Mention
        trigger={trigger || '@'}
        displayTransform={formatMention || defaultFormat}
        data={formatData || data || []}
      />
    </MentionsInput>
    // </GrammarlyEditorPlugin>
  )
}
