import React, { useEffect, useState } from 'react'
import Earnings from 'components/Payouts/Earnings'
import { Button, Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { connectedApiService as api } from 'index'
import { isMobile } from 'helpers'
import DefaultLayout from 'components/layout/DefaultLayout'

const isMobileBrowser = isMobile()
const Commissions = ({ companyId, token, isSupplier }) => {
  const [info, setInfo] = useState(null)

  useEffect(() => {
    const getDateFunction = async () => {
      const { data } = await api.get(
        `earnings/dates?${companyId ? `&companyId=${companyId}` : ''}${
          token ? `&token=${token}` : ''
        }`
      )
      setInfo(data)
    }

    if (!info) {
      getDateFunction()
    }
  }, [companyId, token])

  if (isMobileBrowser) {
    return (
      <DefaultLayout
        title={'Commissions'}
        className='light-theme'
        showClose
        showBack
        showLogo={false}
        isSettingsView={true}
      >
        {info ? (
          <Earnings
            companyId={info.companyId}
            dates={info.dates}
            isPaymentSection
          />
        ) : (
          <></>
        )}
      </DefaultLayout>
    )
  }
  return (
    <>
      {info ? (
        <Container
          style={{ paddingRight: '10rem' }}
          className='form-block pb-0 pt-0'
        >
          <Earnings
            companyId={info.companyId}
            dates={info.dates}
            isPaymentSection
          />
        </Container>
      ) : (
        <></>
      )}
    </>
  )
}

// export default Commissions
const mapStateToProps = ({ auth: { companyId } }) => ({
  companyId,
})

export default withRouter(connect(mapStateToProps, null)(Commissions))
