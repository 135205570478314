import React from 'react'
import { Nav } from 'react-bootstrap'
import { NavTab } from 'react-router-tabs'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
const { Link: NavLink } = Nav

const TabsNav = ({
  className = '',
  align = 'start',
  tabs = [],
  multiple = false,
  sectionTabs = false,
  todayBookingsCount,
  tomorrowBookingsCount,
  yesterdayBookingsCount,
  dayAfterBookingsCount,
  threeDaysAfterBookingsCount,
  fourDaysAfterBookingsCount,
  fiveDaysAfterBookingsCount,
  isAdminBookings = false,
  ...props
}) => {
  // title: title === 'Yesterday' ? `${title} (${yesterdayBookingsCount})` : title === 'Today' ? `${title} (${todayBookingsCount})` :
  //               title === 'Tomorrow' ? `${title} (${tomorrowBookingsCount})` : title
  if (!tabs || !tabs.length) {
    return null
  }
  var multipleClass = ''
  if (multiple && align === 'start') {
    multipleClass = 'display-inline-left'
  } else if (multiple && align === 'end') {
    multipleClass = 'display-inline-right'
  }
  return (
    <div
      className={`${
        multiple
          ? `${multipleClass}`
          : `d-flex justify-content-${align || 'start'}`
      }`}
    >
      {/* <div className={`d-flex justify-content-${align || 'start'}`}> */}
      <Nav
        className={`custom-tabs-nav ${className || ''}`}
        {...props}
        style={
          sectionTabs
            ? { maxWidth: '1000px' }
            : isAdminBookings
            ? { maxWidth: 'fit-content' }
            : {}
        }
      >
        {tabs.map(({ title = '', path, key, day }) => {
          if (path) {
            return (
              <NavTab className='nav-link' to={path} key={path}>
                {/* {title} */}
                {title === 'Yesterday'
                  ? `${title} (${yesterdayBookingsCount})`
                  : title === 'Today'
                  ? `${title} (${todayBookingsCount})`
                  : title === 'Tomorrow'
                  ? `${title} (${tomorrowBookingsCount})`
                  : day === 2
                  ? `${title} (${dayAfterBookingsCount})`
                  : day === 3
                  ? `${title} (${threeDaysAfterBookingsCount})`
                  : day === 4
                  ? `${title} (${fourDaysAfterBookingsCount})`
                  : day === 5
                  ? `${title} (${fiveDaysAfterBookingsCount})`
                  : title}
              </NavTab>
            )
          }
          return (
            <NavLink key={key} eventKey={key}>
              {/* {title} */}
              {title === 'Yesterday'
                ? `${title} (${yesterdayBookingsCount})`
                : title === 'Today'
                ? `${title} (${todayBookingsCount})`
                : title === 'Tomorrow'
                ? `${title} (${tomorrowBookingsCount})`
                : title}
            </NavLink>
          )
        })}
      </Nav>
    </div>
  )
}

// export default TabsNav
const mapStateToProps = ({ appWebsocket }) => ({
  todayBookingsCount: appWebsocket.todayBookingsCount,
  tomorrowBookingsCount: appWebsocket.tomorrowBookingsCount,
  yesterdayBookingsCount: appWebsocket.yesterdayBookingsCount,
  dayAfterBookingsCount: appWebsocket.dayAfterBookingsCount,
  threeDaysAfterBookingsCount: appWebsocket.threeDaysAfterBookingsCount,
  fourDaysAfterBookingsCount: appWebsocket.fourDaysAfterBookingsCount,
  fiveDaysAfterBookingsCount: appWebsocket.fiveDaysAfterBookingsCount,
})

export default withRouter(connect(mapStateToProps, {})(TabsNav))
