import { partnerHostLive, partnerHostStaging } from '../../constants'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
const Host = window.location.hostname
const InfoCard = ({ token, type, item, isPartner }) => {
  return (
    <>
      {item && (
        <div
          className='driver-add-card mb-3'
          style={isPartner ? {} : { justifyContent: 'start' }}
        >
          {type === 'chauffeur' ? (
            <>
              {item.profilePicture ? (
                <div className='add-pic-container-uploaded'>
                  <img
                    className='profile-img'
                    src={item.profilePicture}
                    alt=''
                  />
                </div>
              ) : (
                <div className='add-pic-container-uploaded'>
                  <img
                    className='car-img-chauffeur'
                    src={'/images/icons/icon-chauffeur-black.png'}
                    alt=''
                  />
                </div>
              )}
              <span
                className='info-span'
                style={isPartner ? {} : { paddingLeft: '2rem' }}
              >
                <span className='d-block' style={{ fontWeight: 'bolder' }}>
                  {item.firstName} {item.lastName}
                </span>
                <span> {item.phoneNumber} </span>
              </span>
              {isPartner && (
                <Link
                  className='edit-img'
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/chauffeur/edit/${item._id}`}
                >
                  <img
                    style={{ marginRight: '5px' }}
                    src='/images/edit_icon.png'
                    alt=''
                  />
                </Link>
              )}
            </>
          ) : type === 'car' ? (
            <>
              <img
                src={item.vehicleImage && item.vehicleImage}
                alt=''
                className='d-block car-edit-img'
              />
              <span
                className='info-span'
                style={isPartner ? {} : { paddingLeft: '2rem' }}
              >
                <span className='d-block' style={{ fontWeight: 'bolder' }}>
                  {item.make && item.make.label}{' '}
                  {item.model && item.model.label}
                </span>
                <span style={{ textTransform: 'uppercase' }}>
                  {' '}
                  {item.plateNumber}{' '}
                </span>
              </span>
              {isPartner && (
                <Link
                  className='edit-img'
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/car/edit/${item._id}`}
                >
                  <img src='/images/edit_icon.png' alt='' />
                </Link>
              )}
            </>
          ) : (
            <>
              {item && item.logo ? (
                <div className='add-pic-container-uploaded'>
                  <img className='profile-img' src={item.logo} alt='' />
                </div>
              ) : (
                <div className='add-pic-container-uploaded'>
                  <img
                    className='car-img-chauffeur'
                    src={'/images/icon-company-black.png'}
                    alt=''
                  />
                </div>
              )}
              <span
                className='info-span'
                style={isPartner ? {} : { paddingLeft: '2rem' }}
              >
                <span className='d-block' style={{ fontWeight: 'bolder' }}>
                  {item.name ? item.name : 'N/A'}
                </span>
              </span>
              {isPartner && (
                <Link
                  className='edit-img'
                  to={`${
                    Host === partnerHostLive || Host === partnerHostStaging
                      ? '/'
                      : '/partner/'
                  }driver/app/${token}/settings/edit/${item._id}`}
                >
                  <img src='/images/edit_icon.png' alt='' />
                </Link>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

// export default withRouter(InfoCard)
const mapStateToProps = ({ auth: { isPartner = null, isDriver = null } }) => ({
  isPartner,
  isDriver,
})

export default withRouter(connect(mapStateToProps, {})(InfoCard))
