import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { isMobile } from 'helpers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { supportEmail } from 'actions'
import ResponsiveFixedComponents from './Responsive/ResponsiveFixedComponents'
import SubmitBtn from './SubmitBtn'

const isMobileBrowser = isMobile()

const SupportModal = ({
  show,
  onHide,
  modalLabel,
  supportEmail,
  modalTitle = null,
  bookingId = null,
  supplierId = null,
  afterSubmission = () => {},
}) => {
  const [commentAdded, setCommentAdded] = useState(true)

  const onClose = () => {
    onHide()
  }

  const onSubmit = async () => {
    try {
      if (bookingId && supplierId) {
        var supportComment = document.querySelectorAll(
          'span[contentEditable]'
        )[1].innerText
        if (modalTitle === 'Report' && supportComment === '') {
          setCommentAdded(false)
        } else {
          let supportInfo = {
            supportType: modalTitle,
            supportComment: supportComment,
          }
          await supportEmail(supportInfo, bookingId, supplierId)
          onClose()
          afterSubmission()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {isMobileBrowser && show ? (
        <div>
          <img
            style={{
              width: '18px',
              height: '20px',
              margin: '30px 0 50px 27px',
            }}
            src='/images/icons/close-icon.png'
            onClick={onClose}
          />
          <div style={{ margin: '0 2em' }}>
            <h4>{modalTitle}</h4>
            <div style={{ marginTop: '25px' }}>
              <label>{modalLabel}</label>
              <span
                id='supportCommentMobile'
                className='form-group notes-expandable-partner'
                as='textarea'
                contentEditable='plaintext-only'
                style={{ minHeight: '250px' }}
              ></span>
              {!commentAdded && (
                <p className='error-msg-partner' style={{ marginRight: '0px' }}>
                  Please add a comment.
                </p>
              )}
            </div>
          </div>
          <ResponsiveFixedComponents>
            <SubmitBtn
              className='submit-btn-profile'
              title='Submit'
              onClick={onSubmit}
              style={{ textShadow: 'rgb(255 255 255) 0.25px 0px' }}
              // disabled={isSubmitting}
            />
          </ResponsiveFixedComponents>
        </div>
      ) : (
        <Modal
          size='lg'
          show={show}
          onHide={onClose}
          className='decline-offer-booking-modal modal-90w'
          dialogClassName='modal-90w '
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <Modal.Title
              id='example-modal-sizes-title-lg'
              className='modal-title'
            >
              <h1 className='title'>{modalTitle}</h1>
              <div className='close-btn-wrap'>
                <Button
                  variant='link'
                  onClick={onClose}
                  className={'pr-5 mr-3'}
                >
                  <img
                    src={'/images/icons/modal-close-icon.svg'}
                    alt=''
                    height={50}
                    width={50}
                  />
                </Button>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mt-2'>
              <label>{modalLabel}</label>
              <span
                id='supportComment'
                className='form-group notes-expandable-partner'
                as='textarea'
                contentEditable='plaintext-only'
                style={{ marginRight: '5em' }}
              ></span>
              {!commentAdded && (
                <p
                  className='error-msg-partner'
                  style={{ marginRight: '4.5rem' }}
                >
                  Please add a comment.
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='select-btn select-vehicle partner-btn-desktop'
              variant='brown'
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
export default withRouter(connect(null, { supportEmail })(SupportModal))
